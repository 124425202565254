.section-secondary-navbar {
  overflow: hidden;
  margin-top: rem-calc(5);
  transition: height .3s ease;
  -webkit-transition: height .3s ease;
}

.h-0 {
  height: 0;
}

.h-60 {
  height: rem-calc(55);
  margin-bottom: 1.5rem;
}

.secondary-navbar {
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: $sky-blue;
    align-items: center;
  }

  li {
    float: right;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 13.5px;

    &:last-child {
      padding-left: rem-calc(25) !important;
    }

    &:not(:last-child) {
      padding: {
        right: rem-calc(25) !important;
        left: rem-calc(25) !important;
      }

      &::after {
        @include size(1px, rem-calc(15));
				content: "";
				position: absolute;
				left: 100%;
				top: 50%;
				transform: translateY(-50%);
				background: rgba($grey-light-alt, .7);
      }
    }

    a {
      display: block;
      color: white;
      text-align: center;
      padding: {
        top: rem-calc(14) !important;
        bottom: rem-calc(14) !important;
      }
      text-decoration: none;
    }
  }
}

.navbar-top.is-hidden-touch.is-flex {
  height: rem-calc(55);

  .add-menu.navbar-menu {
		.navbar-item {
			font-weight: 500 !important;

      &.triangle-feature {
        &:after {
          @include triangle(rem-calc(20) rem-calc(13), $primary-lightest, up);
          content: '';
          position: absolute;
          top: rem-calc(42);
          left: 40%;
          right: rem-calc(48);
        }
      }
		}
	}

  .search-dropdown-wrap {
    a.navbar-link {
      i {
        font-weight: 500 !important;
      }
    }
  }

  .navbar-dropdown {
    border-radius: rem-calc(15);

    .navbar-item {
      &:hover {
        background-color: inherit !important;
      }
    }
  }
}

.section-secondary-navbar.is-hidden-touch {
  background-color: $turquoise-lightest;
	align-items: center !important;

 .secondary-navbar {
		ul {
			li {
				font-weight: 600 !important;
			}
 		}
	}

	.navbar-item:hover {
		background-color: $turquoise-lightest;
	}
}

#header {
  .navbar.is-spaced {
    padding-top: 0 !important;
    min-height: auto;
  }
}
