@import "../bulma-custom.scss";

ul.breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  font-size:13px;
  gap: 7px;
  margin:0;
  padding:15px 0 40px;
  @media(max-width:767px){
    font-size: 14px;
  }

  a {
    color: $blue;
    &:hover {
      color: $pink;
    }
  }

  li {
    display: flex;
    align-items: center;
    gap: 7px;
    margin: 0;
    padding: 0;
    
    &::before {
      display: none;
    }

    &.home .breadcrumbs-icon {
      font-size: 1.5em;
      color: $alternative-blue;
    }

    .breadcrumbs-label{
      color: $alternative-blue;
    }

    .breadcrumbs-icon {
      width: 1em;
      height: 1em;
      display: block;
      position: relative;
      font-size: 0.7em;
      color: $alternative-blue;
      
      i {
        position: absolute;
        inset: 0;

        svg {
          width: 100%;
          height: 100%;
          display: block;
        }
      }
    }
  }
}