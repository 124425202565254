.resources-section {
  border-radius: 15px;
  height: 100%;

  .card {
    .card-content {
      padding-top: 0;
    }
  }

  &.horizontal-orientation {
    .card-content {
      padding-left: 0;
    }

    @include media(">tablet") {
      margin: {
        left: rem-calc(5);
        right: rem-calc(5);
      }
    }

    @include media(">=desktop") {
      figure {
        height: 100%;
      
        img {
          height: 100% !important;
          object-fit: cover;
        }
      }
    }

    img {

      @include media('>=desktop') {
        border-radius: rem-calc(15) rem-calc(0) rem-calc(0) rem-calc(15);
      }

      @include media('<=tablet') {
        border-top: {
          left-radius: rem-calc(15);
          right-radius: rem-calc(15);
        };
      }
    }

    &.has-background-white {
      &.is-outlined-white {
        .card {
          .card-content {
            padding: 0;
          }
        }
      }
    }
  }
}

.block_resource {
  .card.is-shadowless.has-background-white.is-outlined-white {
    .card-content {
      padding: 0;
    }
  }

  .resources-section {
    &.is-outlined-white {
      &.has-background-white {
        .card {
          .card-content {
            padding: 0;
          }
        }
      }
    }
  }

  .columns {
    &.resources-section {
      &.horizontal-orientation {
        margin: {
          left: 0 !important;
          right: 0 !important;
        };
      }
    }
  }
}
