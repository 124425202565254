.filter-news {
  overflow: hidden;

  @keyframes dropDownAnimation {
    0% {
      transform: translateY(-30%);
      opacity: .5;
    }

    100% {
      transform: translateY(0%);
      opacity: 1;
    }
  }

  .dropdown-animation {
    animation: dropDownAnimation !important;
    animation-duration: 1s !important;
  }
}
