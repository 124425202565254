.posts {
	position: relative;
	margin-top: rem-calc(20);

	.aside-column & {
		margin-top: rem-calc(40);
	}

	&.is-bordered-left  {

		&:before {
			content: "";
			position: absolute;
			left: 0;
			top: rem-calc(5);
			bottom: rem-calc(5);
			width: rem-calc(7);
			background: transparent;

			.side-block & {
				background: #f6f6f6;
			}
		}
	}
}

.post {
	font-size: rem-calc(15); // 15px
	line-height: 1.4; // 21px
	
	&:not(:last-child) {
		margin-bottom: rem-calc(29);
	}

	.tweets-posts & {

		&:not(:last-child) {
			margin-bottom: rem-calc(10);
		}

		h6, .h6 {
			margin-bottom: 0;
			letter-spacing: -.0294em; // -.5px

			&:not(:last-child) {
				margin-bottom: 0;
			}
		}
	}

	.posts.is-bordered-left > & {
		padding-left: rem-calc(33);
		position: relative;

		@include media(">=tablet") {
			padding-left: rem-calc(23);
		}

		@include media(">=desktop") {
			padding-left: rem-calc(33);
		}

		&:hover {

			&:after {
				background: $link;
			}
		}

		&:after {
			@include animate(background, .15s, ease-out);
			content: "";
			position: absolute;
			left: 0;
			top: rem-calc(5);
			bottom: rem-calc(5);
			width: rem-calc(7);
			background: transparent;
		}
	}

	h6, .h6  {
		font-size: 1.1333em; // 17px
		line-height: 1.4118; // 24px
		
		font-weight: $weight-semibold;
		margin-bottom: 0;

		&:not(:last-child) {
			margin-bottom: rem-calc(4);
		} 
	}
}