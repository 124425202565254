.placeholder-js {
  height: 200px;
  width: 100%;
  background-color: $grey-light-alt;
  color: $white;
  text-align: center;
  border-radius: 15px;
  border: 1px solid $grey-dark;
  font-size: 1.5em;
  position: relative;

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: 0;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}
