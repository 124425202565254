.percents-block {
	padding-top: rem-calc(10);

	@include media(">=desktop") {
		padding-top: 0;
	}

	&:not(:last-child) {
		padding-bottom: rem-calc(43);
	}

	.card & {
		// padding-top: 0;
		padding-bottom: rem-calc(24);
	}

	.side-column & {
		padding-top: rem-calc(5);
		padding-bottom: rem-calc(20);
	}

	&.is-horizontal {

		@include media(">=tablet") {
			display: flex;
			flex-wrap: wrap;
			align-items: flex-start;
			margin-left: rem-calc(-10);
			margin-right: rem-calc(-10);
			width: calc(100% + 20px);
		}

		.percents-row {

			@include media(">=tablet") {
				width: 50%;
				padding: rem-calc(0 10 15);
			}
		}
	}
}

.percents-row {
	display: flex;
	align-items: flex-start;
	flex-direction: row;
	> .is-flex {
		width: 100%;
		.description {
			width: 1px!important;
		}
	}
	> .is-block {
		.description {
			width: 100%!important;
		}
	}
	/****
		temporary disabled
		top alingn the number with the text.
		align-items: center;
	****/
	color: $dark;

	&:not(:last-child) {
		padding-bottom: rem-calc(15);
	}

	.value {
		display: inline-block;
		vertical-align: top;
		font-size: rem-calc(43);
		line-height: 1;
		font-weight: $weight-semibold;
		flex-basis: auto;
		flex-shrink: 0;
		padding-left: rem-calc(30);
		padding-right: rem-calc(12);
		position: relative;
		color: currentColor;

		@include media(">=tablet") {
			font-size: rem-calc(38);
			line-height: 1;
		}

		@include media(">=desktop") {
			font-size: rem-calc(43);
			line-height: 1;
		}

		&.up {

			&:before {
				border-bottom-color: $primary;
			}
		}

		&.down {

			&:before {
				border-bottom-color: $pink;
				transform: translateY(-50%) rotate(-180deg);
			}
		}

		&:before {
			@include triangle(rem-calc(19) rem-calc(13), transparent, up);
			content: "";
			position: absolute;
			left: 0;
			top: 40%;
			transform: translateY(-50%);
			margin-top: rem-calc(5);

			@include media(">=tablet") {
				margin-top: rem-calc(2);
			}

			@include media(">=desktop") {
				margin-top: rem-calc(5);
			}
		}
	}


	.description {
		display: inline-block;
		vertical-align: top;
		font-size: rem-calc(14); // 14px
		line-height: 1.5714;
		flex-grow: 1;
		letter-spacing: -.0286em; // -0.4px
	}
}

.percents-block {
	&:not(.with-and-without-arrow) {
		.percents-row {
			.value {
				&:not(.up, .down) {
					padding-left: 0;
				}
			}
		}
	}
}
/*
.block_stat_numeric, .block_paragraph, .block_promo_flexible, .block_featured_text, .block_resource {
	.percents-row {
		flex-direction: row !important;
	}
}
*/
