.aside-card {
	overflow: visible;
	position: relative;

	.card-content {

		@include media(">=desktop") {
			padding: rem-calc(25 29);
		}
	}

	h5 {
		line-height: 1.5; //33px
		letter-spacing: -.057em; //-.2px
		margin-bottom: 0.24em;
		margin-left: rem-calc(32);
		font-weight: $weight-semibold;
	}
}

.filter-card {
	position: relative;
	height: auto;

	.aside-column .filter-btn-holder + & {
		margin-top: 0 !important;
	}

	&.aside-card {

		&:before {

			@include media(">=tablet") {
				display: none;
			}
		}
	}

	&.search-sort-card {
		overflow: visible;
	}

	&:before {
		@include triangle(rem-calc(46) rem-calc(28), $primary-lightest, up);
		content: '';
		position: absolute;
		bottom: calc(100% - 10px);
		right: rem-calc(48);
	}

	.custom-control {
		float: left;
		clear: both;
	}

	.btn-opener-holder {
		width: 100%;
		clear: both;
	}
}

.aside-filter-form{
	overflow: hidden;
}

.filter-block {

	&:not(:last-child) {
		padding-bottom: rem-calc(2);
	}

	.slide {
		overflow: hidden;
		clear: both;
	}
}

.filter-btn-holder {
	margin: 0 0 0 auto;
	text-align: right;

	.aside-column & {
		margin-bottom: rem-calc(21);

		@include media(">=tablet") {
			display: none;
		}
	}
}

.filter-opener {
	font-size: rem-calc(20);
	font-weight: $weight-semibold;
	padding-right: rem-calc(46);
	color: $info;
	position: relative;

	&.is-active {
		color: $link;
	}

	&:after {
		content: $icon-filter;
		font-family: $icomoon-font-family;
		font-size: rem-calc(34);
		font-weight: $weight-normal;
		line-height: 1;
		color: inherit;
		position: absolute;
		right: 0;
		top: rem-calc(-6);
	}
}

.open-close-opener {
	display: inline-block;
	vertical-align: top;
	position: relative;
	font-weight: $weight-semibold;
	letter-spacing: -0.0118em;
	padding-left: rem-calc(32);
	color: $info;

	.aside-open-close & {
		font-size: rem-calc(16);
		line-height: 1.75; // 28px
		font-weight: $weight-medium;
		letter-spacing: normal;
		color: $link;
		margin-left: rem-calc(22);
		padding-left: 0;
		padding-right: rem-calc(25);

		&:hover {

			&,
			&:before {
				color: $link-hover;
			}
		}

		&:before {
			left: auto;
			right: 0;
			color: inherit;
			font-size: rem-calc(24);
			line-height: 1;
			margin-top: rem-calc(2);
		}
	}

	.aside-open-close.is-open-close-active & {

		&:before {
			margin-top: rem-calc(2);
		}
	}

	&:hover {

		&,
		&:before {
			color: $link;
		}
	}

	&:before {
		@include animate(transform top color, .15s, ease-out);
		content: $icon-chevron-down;
		font-family: $icomoon-font-family;
		font-size: rem-calc(20);
		line-height: 1;
		color: $dark;
		position: absolute;
		left: 0;
		// top: rem-calc(5);
		margin-top: rem-calc(5);
	}

	.is-open-close-active & {

		&:before {
			transform: rotate(180deg);
			// top: rem-calc(3);
			margin-top: rem-calc(3);
		}

		.default-text {
			display: none;
		}

		.hidden-text {
			display: inline-block;
		}
	}

	.hidden-text {
		display: none;
	}
}

.aside-open-close {

	.sub-nav {

		+ .slide {
			margin-top: rem-calc(-8);
			margin-bottom: rem-calc(8);
		}
	}
}