.inputSearch {
    .\!block {
        display: block!important;
    }
    .vs__spinner {
        margin: -0.5em 2em;
        order:-1;
    }
    .v-select {
        border: 1px solid #e7f5f3 !important;
        border-radius: 5px;
        background: white;
        * {
            color: #000!important;
        }
        .vs__selected{
            display: none;
        }
        .vs__selected-options {
            color: #18214d!important;
            background-color: white !important;
            padding: 10px 25px 10px 15px !important;       
        }
        &.vs--searchable{
            input {
                font-size: 0.9rem !important;
                opacity: 1!important;
            }
        }
        .vs__dropdown-menu{
            list-style: none!important;
            border-radius: 5px;
            box-shadow: rgb(0 0 0 / 20%) 0px 5px 5px -3px, rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px;
            li.vs__dropdown-option, li.vs__no-options {
                padding: 5px 10px;
                font-size: 0.9rem;
                list-style: none!important;
                &::before{
                    display: none;
                }
                .text-blue {
                    color: #1E8ED7!important
                }
                &:hover, &.vs__dropdown-option--highlight {
                    background-color: #e7f5f3!important;
                }
            }
        }
    }
}
.overflow-visible {
    overflow: visible!important;
}