.content {
  :not(.block_paragraph) {
    table {
      table-layout: fixed;
      width: 100%;

      border: {
        left-style: none;
        right-style: none;
      };

      tr {

        th,
        td {
          padding: {
            top: rem-calc(5);
            bottom: rem-calc(5);
          };

          text-align: left;
        }
      }
    }
  }
}

.block_paragraph {
  table {
    border: {
      left-style: hidden;
      right-style: hidden;
    };

    thead {
      tr {

        th,
        td {
          border-right: hidden;
          border-bottom: 2px solid $secondary-grey-for-table;

          padding: {
            top: rem-calc(8.5);
            bottom: rem-calc(8.5);
          };
        }
      }
    }

    tbody {
      tr {
        &:not(:last-child) {
          border-bottom: 1px solid $secondary-grey-for-table;
        }

        th,
        td {
          border-right: hidden;

          padding: {
            top: rem-calc(8.5);
            bottom: rem-calc(8.5);
          };
        }
      }
    }
  }
}

table {
  tr {
    th, td {
      a {
        color: $pink !important;

        &:hover {
          color: $pink-dark !important;
        }

        em {
          color: $pink !important;

          &:hover {
            color: $pink-dark !important;
          }
        }

        strong {
          color: $pink !important;

          &:hover {
            color: $pink-dark !important;
          }
        }

        i {
          color: $pink !important;

          &:hover {
            color: $pink-dark !important;
          }
        }

        b {
          color: $pink !important;

          &:hover {
            color: $pink-dark;
          }
        }
      }
    }
  }
}
