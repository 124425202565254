// custom variables and override bulma variables

// Colors
$black:        	 #000;
$grey-darker:    #231f20; // -> heading in aside search filter
$grey-dark:      #585858; // hsl(0, 0%, 35%) -> body text color, footer copyright
$grey:           #585858; // hsl(0, 0%, 46%) -> date color, , text in card, figcaption, news-text
$grey-light-alt: #929292; // hsl(0, 0%, 57%) -> select text color, datepicker placeholder, search field placeholder
$grey-light:     #b5b5b5; // hsl(0, 0%, 71%) -> placeholder, blockquote border
$grey-lighter:   #e0e0e0; // hsl(0, 0%, 88%) -> accordion borders
$off-white: #e8f0ef;
$grey-for-table: #b7b7b7;
$secondary-grey-for-table: #e0e0e0;

$custom-red: #da4944;
$custom-green: #95a329;
$custom-yellow: #de9502;

$pink: #d62c77; // inline link color, more link color
$pink-dark: #b51a5e; // bg of close btn

$blue: #0b5793; // main nav links, links list
$alternative-blue: #18214D;
$blue-dark: #3e4462; // note text under page heading
$blue-darkest: #18214d; // headings, list bullets, active main nav
$sky-blue: #E7F5F3;

$cyan-lighter: #4fa3ae; // gallery arrows, card borders
$cyan: #429aa6; // links in headings, category, learn-cards bg, tags border
$cyan-dark: #46949e; // btn download
$medium-teal: #a7d1d7;

$turquoise-light: #cce5e4; // card-bg
$turquoise-lightest: #e7f5f3; // sections bg, card-bg, progress bar bg
$turquoise-lightest-alt: #f4faf9; // bg-light
$white: #ffffff;

$yellow-warning: #ffdd57;
$green-success: #48c774;
$pink-danger: #f14668;

$primary: $cyan;
$primary-dark: $cyan-dark;
$primary-lighter: $cyan-lighter;
$primary-light: $turquoise-light;
$primary-lightest: $turquoise-lightest;

$info: $blue;
$info-dark: $blue-dark;

$light: $turquoise-lightest-alt;
$dark: $blue-darkest;

$text: $grey-dark;

// Link colors
$link: $pink;
$link-dark: $pink-dark;
$link-hover: $link-dark;
$link-focus: $link-hover;
$link-active: $link-hover;

// Typography
$family-sans-serif: 'Montserrat', -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;

$rem-base: 16px;

$size-4: 1.875rem; // 30px
$size-6: 1.0625rem; // 17px

$size-medium: 1rem; // 16px
$size-smaller: .9375rem; // 15px
$size-large: $size-4; // 30px

// Responsiveness

// The container horizontal gap, which acts as the offset for breakpoints
$gap: 40px;
$tablet: 768px;
$fullhd: 1200px + (2 * $gap);

// Miscellaneous
$radius-xsmall: 2px;
$radius-small: 5px;
$radius: 8px;
$radius-large: 15px;
$radius-xlarge: 25px;

$body-min-width: 320px;

$column-gap: 1.25rem; // 20px

$body-font-size: 1.0625em; // 17px
$body-weight: $weight-medium; // 500
$body-line-height: 1.53; // 26px

$hr-background-color: $grey-lighter;
$hr-height: 1px;

$strong-color: inherit;
$strong-weight: $weight-semibold;

$section-padding: 3rem 1.25rem;
$section-padding-medium: 9rem 1.25rem;
$section-padding-large: 18rem 1.25rem;

$content-heading-color: $dark;
$content-heading-weight: $weight-bold;

$content-blockquote-background-color: transparent;
$content-blockquote-border-left: none;
$content-blockquote-padding: 0 0 0 3.125rem; // 50px

$control-border-width: 1px;

$control-line-height: 1.7333; // 15/26

$control-padding-horizontal: calc(1.3333em - #{$control-border-width}); // 15px

// Buttons
$button-border-width: $control-border-width;
$button-padding-vertical: calc(0.824em - #{$button-border-width}); // 13px
$button-padding-horizontal: 1.2941em; // 22px

// Tags
$tag-radius: $radius-small;

// Forms
$input-background-color: $primary-lightest;
$input-border-color: $input-background-color;
$input-shadow: none;
$input-placeholder-color: $grey-light;
$input-focus-border-color: $dark;
$input-focus-box-shadow-color: rgba($input-focus-border-color, 0.25);
$input-arrow: $dark;
$input-radius: $radius-small;

$label-color: $dark;
$label-weight: $weight-medium;

// Cards
$card-color: $grey;
$card-background-color: transparent;
$card-radius: $radius-large;
$card-content-padding: 2rem 1.625rem 1.625rem; // 32/26/26
$card-header-padding: 0.75rem 1.625rem; // 12/26
$card-footer-padding: 0.75rem 1.625rem; // 12/26

// Navbar
// $navbar-background-color: $white;
$navbar-breakpoint: $tablet;
$navbar-height: 7.3125rem; // 117/216px
$navbar-padding-vertical: 1rem;
$navbar-padding-horizontal: 1.25rem;
$navbar-item-color: $info;
$navbar-item-active-color: $dark;
$navbar-dropdown-border-top: none;
$navbar-dropdown-radius: 0;
$navbar-dropdown-item-hover-color: $link;
$navbar-dropdown-arrow: $info;
$navbar-divider-background-color: #eee;
$navbar-divider-height: 1px;

// Progress
$progress-bar-background-color: $primary-lightest;

// Notification
$notification-padding: 1.375rem 2.5rem 1.375rem 1.5rem;
$notification-padding-ltr: 1.375rem 2.5rem 1.375rem 1.5rem;
$notification-padding-rtl: 1.375rem 1.5rem 1.375rem 2.5rem;

// Footer
$footer-background-color: $primary-lightest;
$footer-padding: 3.125rem 1.25rem 7.5rem; // 50/20/120
