@mixin soft_transition($prop: all) {
  -webkit-transition: $prop 0.3s ease-in-out;
  -moz-transition: $prop 0.3s ease-in-out;
  transition: $prop 0.3s ease-in-out;
}

.icon-classic-fill {
  fill: currentColor;
}

.icon-transparent-fill {
  fill: transparent;
  font-weight: bold;
}

.rotate-icon {
  @include animate(transform color, .15s, ease-out);
  transform: translateY(-50%) rotate(90deg);
}

.rotate-icon-variant {
  @include animate(transform color, .15s, ease-out);
  transform: rotate(90deg);
}

.icon {
  svg {
    width: 2em;
    height: 1em;
    @include soft_transition;
  }

  @at-root .collapse.is-open &--rotates svg {
    transform: rotate(180deg);
  }

  &.is-small svg {
    width: .75em;
    height: .75em;
  }

  &.is-medium svg {
    width: 1.33em;
    height: 1.33em;
  }

  &.is-large svg {
    width: 2em;
    height: 2em;
  }

  &.is-x-large {
    width: 4rem;
    height: 4rem;

    svg {
      width: 3rem;
      height: 3rem;
    }
  }

  &--fav {
    padding: .25rem;
    width: 1.75rem;
    height: 1.75rem;

    @at-root .show-times-collapse__header:hover &:not(.is-active) {
      color: #aaa;
    }
  }

  @include desktop {
    &.is-x-large-desktop {
      width: 4rem;
      height: 4rem;

      svg {
        width: 3rem;
        height: 3rem;
      }
    }
  }
}
