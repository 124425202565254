.news-carousel {

	.tns-ovh {
		padding: rem-calc(40) rem-calc(30);
		margin: rem-calc(-25) rem-calc(-45);
		overflow-y: visible;
	}

	//.mask {
	//	overflow: hidden;
	//	margin: rem-calc(-40 -20);
	//	padding: rem-calc(40 10);
	//	position: relative;
	//
	//	@include media(">=desktop") {
	//		margin-left: rem-calc(-38);
	//		margin-right: rem-calc(-38);
	//		padding-left: rem-calc(18);
	//		padding-right: rem-calc(18);
	//	}
	//}

	.slides {
		display: flex;
	}

	.slide {
		display: flex;
		width: 100%;
		flex-basis: 100%;
		flex-shrink: 0;
		flex-grow: 1;
		padding-left: rem-calc(10);
		padding-right: rem-calc(10);

		@include media(">=tablet") {
			width: 50%;
			flex-basis: 50%;
		}

		@include media(">=desktop") {
			width: 33.33%;
			flex-basis: 33.33%;
			padding-left: rem-calc(20);
			padding-right: rem-calc(20);
		}
	}
}

.carousel-controls {

	@include media("<xl-fullhd") {
		display: flex;
		justify-content: center;
		padding-top: rem-calc(30);
	}
}

.button-control {
	@include size(rem-calc(40));
	border-radius: 50%;
	background: $white;
	border: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: rem-calc(32);
	line-height: 1;
	font-weight: $weight-normal;
	color: $primary-lighter;
	padding: rem-calc(0 2 0 0);
	margin: rem-calc(0 16);

	@include media(">=desktop") {
		@include size(rem-calc(52));
		font-size: rem-calc(38);
		padding-top: rem-calc(2);
	}

	@include media(">=xl-fullhd") {
		margin: 0;
		position: absolute;
		left: rem-calc(-82);
		top: 50%;
		transform: translateY(-50%);
		z-index: 5;
	}

	&:hover {
		background: $primary;
		color: $white;
	}

	&.is-next {
		padding-right: 0;
		padding-left: rem-calc(2);

		@include media(">=xl-fullhd") {
			left: auto;
			right: rem-calc(-82);
		}
	}

	.icon {
		@include size(auto);
	}
}

.tns-slider {
	&.tns-carousel {
		display: flex;
		align-items: stretch;
	}

	.tns-item {
		padding-right: 0;
	}
}

div[id^='tns'], div[id*=' tns']{
	.tns-item {
		.card {
			&.card-news {
				&.is-shadowless {
					&:not(.highlight-carousel-card) {
						@include media(">=tablet") {
							padding-right: 0;
						}
					}
				}
			}
		}
	}
}

.block_news_carousel {
	@include media("<tablet") {
		margin-top: rem-calc(10);
	}

	.section.news-section {
		.section-heading {
			align-items: center !important;

			.link-view {
				margin-bottom: 0 !important;
			}
		}

		@include media('<tablet') {
			margin: {
				left: -1.5rem;
				right: -1.5rem;
			}

			.news-carousel {
				padding: {
					left: rem-calc(10);
					right: rem-calc(10);
				}
			}
		}
	}
}

.carousel-featured {
	.carousel-mobile-version {
		.card {
			&.card-news {
				margin: {
					top: rem-calc(10);
					bottom: rem-calc(10);
				}
				padding: {
					top: rem-calc(8);
					bottom: rem-calc(8);
				};
			}
		}
	}
}
