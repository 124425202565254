.card {
  //font-weight: $weight-semibold;
  font-weight: 500;
  line-height: 1.4118; // 17px/24px
  letter-spacing: -0.0294em; // -0.5px
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  &-is-not-rounded {
    @include media("<=tablet") {
      border-radius: 0;
    }
  }

  &.is-flex {
    &.is-flex-direction-column {
      &.has-background-white {
        &.is-outlined-white {
          .card-content {
            padding-left: 0;
            margin-left: 0;
          }
        }
      }
    }
  }

  @for $i from 1 through 5 {
    @at-root .column.is-#{$i} & .card-content .columns,
      .column.is-#{$i}-desktop & .card-content .columns {
      display: block;

      .column {
        width: 100%;
      }
    }
  }

  .card-image {
    width: 100%;
    flex-shrink: 0;

    figure {
      margin-left: 0;
      margin-right: 0;
    }

    img {
      object-fit: cover;
      object-position: 50% 50%;
    }
  }

  .card-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    word-break: break-word;

    @include media(">=tablet", "<desktop") {
      padding: rem-calc(20);
      word-wrap: break-word;
      hyphens: auto;
      -ms-hyphens: auto;
      -moz-hyphens: auto;
      -webkit-hyphens: auto;
    }
  }

  .partner-logo {
    margin-bottom: rem-calc(10);

    @include media(">=desktop") {
      margin-bottom: rem-calc(13);
    }

    img {
      height: 50px;
      width: auto;
    }
  }

  h4 {
    margin-bottom: 0.9091em; // 20px
  }

  h5 {
    letter-spacing: normal;
    margin-bottom: 0.4091em; // 9px
  }

  p {
    &:not(:last-child) {
      margin-bottom: 1.4118em;
    }

    + ul {
      margin-top: -0.5882em; // -10px
    }
  }

  ul {
    font-weight: 500;
    //font-weight: $weight-medium;

    > li {
      &:before {
        top: 0.5em; // 8px
      }
    }
  }

  .link-more {
    margin-top: auto;
    letter-spacing: normal;

    @include media(">=desktop") {
      margin-bottom: rem-calc(-8);
    }
  }

  .chart-holder {
    padding-top: rem-calc(15);
    padding-bottom: rem-calc(20);

    img {
      vertical-align: top;
    }
  }

  .content {
    &.is-size-smaller {
      letter-spacing: normal;

      h5 {
        font-size: 1.4667em; // 22px
        line-height: 1.0909; // 24px
      }
    }
  }

  .content-wrap {
    width: 100%;
    display: flex;
    padding-bottom: rem-calc(15);

    .icon-holder {
      flex-shrink: 0;
      width: rem-calc(72);

      .icon {
        @include size(auto);
        font-size: rem-calc(58);
        line-height: 1;
        font-weight: $weight-normal;
        margin-left: rem-calc(-6);
      }
    }

    .text-holder {
      flex-grow: 1;
    }
  }

  &.is-horizontal {
    flex-direction: row;
  }

  &.is-horizontal:not(.card-news) {
    @include media(">=desktop") {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .card-image {
      @include media(">=desktop") {
        display: flex;
        width: 50%;
      }

      .image {
        width: 100%;

        @include media(">=desktop") {
          padding-top: 0;
        }
      }
    }

    .card-content {
      @include media(">=desktop") {
        width: 50%;
        flex-grow: 1;
        min-height: rem-calc(263);
      }

      &:not(.content-promo) {
        @include media(">=widescreen") {
          padding-left: rem-calc(43);
        }
      }
    }

    .content {
      font-size: 0.9412em; // 16px
      line-height: 1.375; // 22px
      letter-spacing: -0.0312em; // -.5px

      @include media(">=desktop") {
        padding-top: rem-calc(20);
      }

      &:not(:last-child) {
        margin-bottom: rem-calc(12);

        @include media(">=desktop") {
          margin-bottom: rem-calc(50);
        }
      }

      .link-more {
        font-size: 1em;
      }
    }

    h4 {
      font-size: 1.375em; // 22px
      line-height: 1.1818; // 26px
      margin-bottom: 0.6364em; // 14px

      @include media(">=desktop") {
        font-size: 1.875em; // 30px
        line-height: 1.1333; // 34px
        letter-spacing: -0.0167em; // -.5px
        margin-bottom: 0.5em; // 15px
      }
    }

    p {
      + ul {
        margin-top: -0.6875em; // -11px
      }
    }

    ul {
      > li {
        &:before {
          top: 0.4375em; // 7px
        }
      }
    }

    ul.is-li-vertical-space-small {
      > li {
        + li {
          margin-top: rem-calc(3);
        }
      }
    }

    &.is-full-width {
      border-top-right-radius: rem-calc(15);
      border-bottom-right-radius: rem-calc(15);

      .card-image {
        @include media(">=tablet") {
          width: 50%;
          display: flex;
          padding-top: 0;
        }

        @include media(">=desktop") {
          width: 32.7%;
        }
      }

      .card-content {
        @include media(">=tablet") {
          width: 50%;
          flex-grow: 1;
        }

        @include media(">=desktop") {
          width: 67.3%;
          min-height: 1px;
          padding-top: rem-calc(30);
          padding-bottom: rem-calc(25);
        }

        @include media(">=fullhd") {
          padding-left: rem-calc(48);
        }
      }

      .content {
        padding-top: 0;
        font-size: 1em;
        line-height: 1.4118; // 24px
      }

      h5 {
        margin-bottom: 0.3182em; // 7px
      }

      ul {
        > li {
          &:before {
            top: 0.4706em; // 8px
          }
        }
      }

      .two-columns {
        @include media(">=desktop") {
          column-count: 2;
          column-gap: rem-calc(32);
        }

        p,
        ul {
          @include media(">=desktop") {
            break-inside: avoid;
          }
        }

        p + ul {
          @include media(">=desktop") {
            margin-top: 0;
          }
        }

        ul {
          padding-top: rem-calc(5);
        }
      }
    }
  }

  &:not(.is-horizontal) {
    .card-image {
      + .card-content {
        @include media("<tablet") {
          padding-top: rem-calc(26);
        }

        @include media(">=desktop") {
          padding-top: rem-calc(23);
        }
      }
    }
  }

  &.is-horizontal-mobile {
    @include media("<tablet") {
      flex-direction: row;
      flex-wrap: nowrap;
      padding-top: 0;
      padding-bottom: rem-calc(3);
    }

    .card-image {
      @include media("<tablet") {
        width: 27.4667%;
        border-radius: rem-calc(3);
        margin: rem-calc(5 23 0 0);
        align-self: flex-start;
      }

      + .card-content {
        @include media("<tablet") {
          padding-top: 0;
        }
      }
    }

    .card-content {
      @include media("<tablet") {
        padding: 0;
      }
    }
  }

  &.is-fully-clickable {
    &:hover {
      filter: brightness(0.95);
      cursor: pointer;
    }
  }

  &-newsletter {
    margin-top: rem-calc(15);

    @include media(">=tablet") {
      margin-top: 0;
    }

    .card-content {
      padding-top: rem-calc(32);
      padding-bottom: rem-calc(32);

      @include media(">=tablet") {
        padding-bottom: rem-calc(28);
      }
    }

    .content {
      &:not(:last-child) {
        margin-bottom: rem-calc(12);
      }
    }

    h5 {
      margin-bottom: 0.6818em; // 15px

      @include media(">=tablet") {
        margin-bottom: 0.5909em; // 13px
      }
    }
  }

  &-news {
    line-height: 1.53; // 26px
    font-weight: $weight-medium;
    padding: rem-calc(10) rem-calc(16);
    position: relative;
    overflow: visible;
    flex-direction: column;
    background-color: transparent;
    @include animate(all, 0.15s, ease-out);

    @include media(">=desktop") {
      padding-top: rem-calc(17);
    }

    &:not(:last-child) {
      margin-bottom: rem-calc(20);
    }

    .news-articles & {
      color: #6a6a6a;
    }

    // This breaks the behavior of the cards, for the moment it will be deactivated
    &.highlight-carousel-card:hover {
      background-color: #fff;
      box-shadow: 0 rem-calc(10) rem-calc(30) rgba($black, 0.13) !important;

      &:before {
        opacity: 1;
        visibility: visible;
      }
    }

    &.no-hover-shadow:hover,
    &.no-hover-shadow:hover.is-shadowless:hover {
      box-shadow: none !important;
    }

    .card-image {
      flex-shrink: 0;
      border-radius: rem-calc(15 15 0 0);
      overflow: hidden;
      z-index: 1;
    }

    .card-content {
      padding-top: rem-calc(22);
      padding-bottom: rem-calc(22);
      flex-grow: 1;
      padding-left: 0;
      padding-right: 0;
      z-index: 1;

      h5 {
        a {
          font-size: 17px;
          font-weight: 600;
        }
      }
    }

    .meta {
      display: flex;

      .date {
        margin-left: auto;
        margin-bottom: rem-calc(4);
        padding-left: rem-calc(10);
      }

      .category-label {
        margin-bottom: rem-calc(4);
        max-width: 75%;
      }
    }

    h4,
    .h4 {
      margin-bottom: 0.41em;
      letter-spacing: normal;
    }

    p {
      &:not(:last-child) {
        margin-bottom: 0.9em; // 20px

        @include media(">=tablet") {
          margin-bottom: 0.9em; // 20px
        }
      }

      + .tags {
        padding-top: 0;
      }
    }

    .tags {
      padding-top: rem-calc(9);
    }

    &.is-horizontal-mobile {
      @include media("<tablet") {
        font-size: 0.7647em; // 13px
        line-height: 1.6923; // 22px
      }

      &:before {
        @include media("<tablet") {
          display: none;
        }
      }

      .meta {
        @include media("<tablet") {
          display: block;
        }

        .date {
          @include media("<tablet") {
            padding-left: 0;
          }
        }
      }

      .category-label {
        @include media("<tablet") {
          display: none;
        }
      }

      .content {
        @include media("<tablet") {
          display: flex;
          flex-direction: column;
        }
      }

      h5 {
        @include media("<tablet") {
          // font-size: 1em;
          font-size: 1.3077em; // 17px
          line-height: 1.4118; // 24px;
          font-weight: $weight-semibold;
          margin-bottom: 0.1765em; // 3px
          order: -1;
        }
      }
    }

    &.is-horizontal {
      flex-direction: row;
      flex-wrap: nowrap;
      padding-top: 0;
      padding-bottom: rem-calc(3);

      .posts & {
        &:not(:last-child) {
          margin-bottom: rem-calc(40);

          &.no-tags {
            margin-bottom: rem-calc(31);
          }
        }
      }

      .posts.is-bordered-left > & {
        padding-left: rem-calc(33);
        position: relative;

        @include media(">=tablet") {
          padding-left: rem-calc(23);
        }

        @include media(">=desktop") {
          padding-left: rem-calc(33);
        }

        &:hover,
        &.active {
          &:after {
            background: $link;
          }
        }

        &:after {
          @include animate(background, 0.15s, ease-out);
          content: "";
          position: absolute;
          left: 0;
          top: rem-calc(5);
          bottom: rem-calc(5);
          width: rem-calc(7);
          background: transparent;
        }

        .meta {
          order: -1;
        }
      }

      .news-cards-container & {
        &:not(:last-child) {
          margin-bottom: rem-calc(12);
        }

        h5 {
          order: initial;
          letter-spacing: -0.0294em; // -.5px
        }

        .meta {
          .date {
            margin-bottom: 0;
          }
        }
      }

      .news-articles & {
        padding-top: rem-calc(18);

        &:not(:first-child) {
          margin-top: 0;
        }

        &:not(:last-child) {
          margin-bottom: 0;
          padding-bottom: rem-calc(18);
        }
      }

      &:before {
        display: none;
      }

      .card-image {
        width: 27.4667%;
        border-radius: rem-calc(3);
        margin: rem-calc(5 23 0 0);
        align-self: flex-start;
        flex-shrink: 0;

        @include media(">=desktop") {
          border-radius: rem-calc(4);
        }

        + .card-content {
          padding-top: 0;
        }
      }

      .card-content {
        padding: 0;
        flex-grow: 1;
      }

      .meta {
        display: block;

        .date {
          padding-left: 0;
        }
      }

      .category-label {
        display: none;
      }

      .content {
        display: flex;
        flex-direction: column;
      }

      h5 {
        // font-size: 1em; // 17px
        // line-height: 1.4118; // 24px;
        // font-weight: $weight-semibold;
        // margin-bottom: .1765em; // 3px
        order: -1;
      }
    }

    &.is-horizontal-desktop {
      padding-top: 0;

      @include media(">=tablet") {
        flex-direction: row;
        flex-wrap: nowrap;
      }

      &:not(:last-child) {
        margin-bottom: rem-calc(10);

        @include media(">=tablet") {
          margin-bottom: rem-calc(46);
        }
      }

      &:before {
        display: none;
      }

      .card-image {
        border-radius: rem-calc(15);

        @include media(">=tablet") {
          width: 38.5%;
          border-radius: $radius-large;
          margin: rem-calc(5 23 0 0);
          align-self: flex-start;
          flex-shrink: 0;
        }

        @include media(">=desktop") {
          margin-right: rem-calc(30);
        }

        + .card-content {
          padding-top: rem-calc(20);
          padding-bottom: rem-calc(20);

          @include media(">=tablet") {
            padding-top: 0;
            padding-bottom: 0;
          }
        }
      }

      .card-content {
        padding-top: 0;

        @include media(">=tablet") {
          padding: 0;
          flex-grow: 1;
        }
      }

      .meta {
        display: block;
        margin-bottom: rem-calc(17);

        @include media(">=tablet") {
          margin-bottom: rem-calc(15);
        }

        .date {
          padding-left: 0;
        }
      }

      .category-label {
        display: none;
      }

      h5 {
        margin-bottom: 0.1765em; // 3px

        @include media(">=tablet") {
          margin-bottom: 0.2727em; // 6px
        }
      }
    }

    &.is-small {
      font-size: 0.7647em; // 13px
      line-height: 1.6923; // 22px

      h5 {
        font-size: 1.3077em; // 17px
        line-height: 1.4118; // 24px;
        font-weight: $weight-semibold;
        margin-bottom: 0.1765em; // 3px
      }
    }
  }

  &-hero {
    &.promo-flexible {
      .content {
        p {
          max-width: 100% !important;
        }
      }
    }

    overflow: visible;

    @include media(">=tablet") {
      flex-direction: row-reverse;
      border-radius: $radius-xlarge;
    }

    .card-image {
      border-radius: $radius-large $radius-large 0 0;
      overflow: hidden;
      @include media("<=tablet") {
        border-radius: 0;
      }

      @include media(">=tablet") {
        width: 50%;
        flex-shrink: 0;
        display: flex;
        border-radius: $radius-large $radius-xlarge $radius-xlarge $radius-large;
      }

      .image {
        @include media(">=tablet") {
          padding-top: 0;
          width: 100%;
          min-height: rem-calc(320);
        }

        @include media(">=desktop") {
          min-height: rem-calc(420);
        }
      }
    }

    .card-content {
      padding: rem-calc(28 28 32);

      @include media(">=tablet") {
        width: 50%;
        flex-grow: 1;
        min-height: rem-calc(270);
      }

      @include media(">=desktop") {
        min-height: rem-calc(370);
        padding: rem-calc(44 46 44);
        padding-top: rem-calc(68) !important;
      }
    }

    .content {
      font-size: 0.9412em; // 16px
      line-height: 1.375; // 22px
      letter-spacing: -0.0312em; // -.5px

      @include media(">=tablet") {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      @include media(">=desktop") {
        font-size: 1.0588em; // 18px
        line-height: 1.3333; // 24px
        letter-spacing: -0.0389em; // -.7px
      }

      &:not(:last-child) {
        margin-bottom: rem-calc(17);
      }

      h2 {
        font-size: 1.375em; // 22px
        line-height: 1.1818; // 26px

        @include media(">=desktop") {
          font-size: 2.4444em; // 44px
          line-height: 1.0909; // 48px
          letter-spacing: -0.0159em; // -.7px
          margin-bottom: 15px;
        }
      }

      p {
        max-width: rem-calc(400);
      }
    }

    .made-by {
      @include media(">=tablet") {
        flex-shrink: 0;
      }
    }

    &.full-width {
      @include media(">=tablet") {
        border-radius: 0;
        margin-left: rem-calc(-20);
        margin-right: rem-calc(-20);
        padding-left: rem-calc(20);
        padding-right: rem-calc(20);
        width: calc(100% + 40px);
        max-width: none;
        margin-top: rem-calc(15);
      }

      @include media(">=desktop") {
        margin-left: calc((100vw - 944px) / -2);
        margin-right: calc((100vw - 944px) / -2);
        padding-left: calc((100vw - 944px) / 2);
        padding-right: calc((100vw - 944px) / 2);
        width: 100vw;
        margin-top: rem-calc(20);
      }

      @include media(">=widescreen") {
        margin-left: calc((100vw - 1136px) / -2);
        margin-right: calc((100vw - 1136px) / -2);
        padding-left: calc((100vw - 1136px) / 2);
        padding-right: calc((100vw - 1136px) / 2);
        margin-top: rem-calc(11);
      }

      @include media(">=fullhd") {
        margin-left: calc((100vw - 1200px) / -2);
        margin-right: calc((100vw - 1200px) / -2);
        padding-left: calc((100vw - 1200px) / 2);
        padding-right: calc((100vw - 1200px) / 2);
      }

      .card-hero-section & {
        @include media("<tablet") {
          margin-bottom: rem-calc(48);
        }
      }

      .card-image {
        @include media(">=tablet") {
          border-radius: $radius-large 0 0 $radius-large;
          margin: rem-calc(-30 -20 -30 0);
          width: calc(50% + 20px);
        }

        @include media(">=desktop") {
          margin: rem-calc(-45) calc((100vw - 944px) / -2) rem-calc(-45) 0;
          width: 50vw;
        }

        @include media(">=widescreen") {
          margin-right: calc((100vw - 1136px) / -2);
        }

        @include media(">=fullhd") {
          margin-right: calc((100vw - 1200px) / -2);
        }
      }

      .card-content {
        @include media(">=tablet") {
          padding-left: 0;
        }

        @include media(">=desktop") {
          padding-bottom: rem-calc(24);
          padding-top: rem-calc(54) !important;
        }
      }
    }

    &.full-width-variant {
      @include media(">=tablet") {
        border-radius: 0;
        margin-left: rem-calc(-20);
        margin-right: rem-calc(-20);
        padding-left: rem-calc(20);
        padding-right: rem-calc(20);
        width: calc(100% + 40px);
        max-width: none;
        margin-top: rem-calc(15);
      }

      @include media(">=desktop") {
        margin-left: calc((100vw - 944px) / -2);
        margin-right: calc((100vw - 944px) / -2);
        //padding-left: calc((100vw - 944px) / 2);
        //padding-right: calc((100vw - 944px) / 2);
        width: 100vw;
        margin-top: rem-calc(20);
      }

      @include media(">=widescreen") {
        margin-left: calc((100vw - 1136px) / -2);
        margin-right: calc((100vw - 1136px) / -2);
        padding-left: calc((100vw - 1136px) / 2);
        padding-right: calc((100vw - 1136px) / 2);
        margin-top: rem-calc(11);
      }

      @include media(">=fullhd") {
        margin-left: calc((100vw - 1200px) / -2);
        margin-right: calc((100vw - 1200px) / -2);
        padding-left: calc((100vw - 1200px) / 2);
        padding-right: calc((100vw - 1200px) / 2);
      }

      .card-hero-section & {
        @include media("<tablet") {
          margin-bottom: rem-calc(48);
        }
      }

      .card-image {
        @include media(">=tablet") {
          border-radius: 0 $radius-large $radius-large 0;
          margin: rem-calc(-30 -20 -30 -30);
          width: calc(50% + 20px);
        }

        @include media(">=desktop") {
          margin: rem-calc(-45) calc((100vw - 944px) / -2) rem-calc(-45) 50;
          width: 50vw;
        }

        @include media(">=widescreen") {
          margin-left: calc((100vw - 1136px) / -2);
        }

        @include media(">=fullhd") {
          margin-left: calc((100vw - 1200px) / -2);
        }
      }

      .card-content {
        @include media(">=tablet") {
          padding-left: rem-calc(100) !important;
        }

        @include media(">=desktop") {
          padding-bottom: rem-calc(24);
          padding-top: rem-calc(54) !important;
        }
      }
    }

    &:not(.full-width) {
      .card-hero-section & {
        @include media(">=tablet") {
          margin-bottom: rem-calc(-34);
        }

        @include media(">=desktop") {
          margin-bottom: rem-calc(-46);
        }
      }
    }
  }

  &-contact {
    line-height: 1.5294; // 26px
    font-weight: $weight-normal;
    // position: fixed;
    right: 0;
    // bottom: 25px;

    width: 260px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: 0 rem-calc(10) rem-calc(20) rgba($black, 0.1);
    z-index: 10;
    height: auto;

    &.closed-mode {
      width: rem-calc(50);
    }

    @include media("<tablet") {
      font-size: rem-calc(14);
      max-width: rem-calc(200);
    }

    .contacts-list {
      li {
        word-break: break-word;

        @include media("<tablet") {
          padding-left: rem-calc(23) !important;

          .icon {
            top: rem-calc(3);
            font-size: 1.025rem;
          }
        }
      }
    }

    // @include media("<xl-fullhd") {
    // 	top: 70%;
    // }

    .card-content {
      padding: 1.375rem;

      @include media("<tablet") {
        padding: rem-calc(9.6 0 0 12);
      }

      .toggle-arrow {
        span.icon:hover {
          svg {
            path {
              stroke: $pink-dark;
            }
          }
        }
      }
    }

    ul {
      font-weight: $weight-normal;
    }

    .category-label {
      display: block;
      margin-bottom: rem-calc(2);
    }
  }

  &-author {
    font-size: 0.7647em; // 13px
    line-height: 1.7; // 22px
    font-weight: $weight-medium;
    letter-spacing: normal;

    .card-content {
      padding-top: rem-calc(30);
      padding-bottom: rem-calc(22);

      &:first-child {
        flex-grow: 0;
      }

      + .card-content {
        padding-top: rem-calc(25);
        padding-bottom: rem-calc(28);
      }
    }

    h5,
    .h5 {
      font-size: 1.5385em; // 20px
      line-height: 1.2;
      font-weight: $weight-semibold;
    }

    h6,
    .h6 {
      font-size: 1.1538em; // 15px
      line-height: 1.2;
      letter-spacing: -0.02em; // -.3px
      margin-bottom: 0.3333em; // 5px
    }

    .author-title {
      h6,
      .h6 {
        display: inline-block;
        vertical-align: middle;
        margin-right: rem-calc(13);
      }

      .social-networks {
        display: inline-block;
        vertical-align: middle;
        padding-bottom: rem-calc(3);

        li {
          display: inline;
          margin: 0;

          &:not(:last-child) {
            margin-right: rem-calc(4);
          }
        }
      }
    }

    .content-wrap {
      @include media("<phone") {
        display: block;
      }

      &:last-child {
        padding-bottom: 0;
      }
    }

    .photo-holder {
      @include size(rem-calc(104));
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      margin: 0 auto rem-calc(40);

      @include media(">=phone") {
        margin: rem-calc(0 18 22 0);
      }

      .icon {
        @include size(auto);
        font-size: rem-calc(64);
        line-height: 1;
        font-weight: $weight-normal;
      }

      img {
        @include size(100%, 120%);
        border-radius: 10%;
        object-fit: cover;
        object-position: 50% 25%;
        margin-top: 20%;
      }
    }

    .text-holder {
      flex-grow: 1;
    }
  }
  height: 100%;
}

.search-sort-card {
  margin-bottom: rem-calc(15); // 15px
  height: auto;

  &.filter-card {
    @include media("<desktop") {
      margin-bottom: rem-calc(44);
    }
  }

  .card-content {
    padding-top: rem-calc(20);
    padding-bottom: rem-calc(20);

    .search-sort-form {
      .control {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        justify-content: space-between;
        height: 100%;
      }
    }
  }
}

.card-title {
  padding: rem-calc(0 30 10 0);
  position: relative;

  h5,
  .h5 {
    margin-bottom: 0;
  }

  .icon-social-media {
    position: absolute;
    right: -2px;
    top: 0;

    .icon {
      @include size(auto);
      font-size: rem-calc(28);
      line-height: 1;
    }
  }
}

.icon-social-media {
  &[class*="has-text-"] {
    &:not(.link-more):not(.button) {
      &:hover {
        color: $link !important;
      }
    }
  }
}

.news-cards-container {
  max-height: rem-calc(220);
  overflow: auto;
  padding-right: rem-calc(10);

  @include media(">=tablet") {
    max-height: rem-calc(270);
  }

  @include media("<tablet") {
    overflow: auto;
  }
}

.news-articles {
  padding-bottom: rem-calc(47);
}

.padding-card-horizontal-none {
  .card-content {
    padding-left: 0;
    padding-right: 0;
  }
}

.block_smartbrief,
.block_stat_chart,
.block_featured_text {
  .card {
    &.is-shadowless {
      &.is-outlined-white {
        &.has-background-white {
          border-radius: 0;

          .card-content {
            padding: 0;
            border-radius: 0;
          }
        }
      }
    }
  }
}

section {
  &.card {
    &.is-shadowless {
      &.is-outlined-white {
        &.has-background-white {
          .card-content {
            padding: 0;
            border-radius: 0;
          }
        }
      }
    }
  }
}

.side-column {
  &.block_featured_text {
    &:not(.is-pulled-left) {
      &:not(.is-pulled-right) {
        .card {
          margin-bottom: rem-calc(20);
        }
      }
    }
  }
}

.block_paragraph,
.block_richtext {
  .side-column {
    figure.image {
      figcaption.made-by {
        margin-bottom: rem-calc(25);
      }
    }
  }
}

@keyframes expand-floating-box {
  0% {
    width: rem-calc(62.5);
    height: rem-calc(170);
  }

  25% {
    width: rem-calc(125);
    height: rem-calc(170);
  }

  50% {
    width: rem-calc(190);
    height: rem-calc(170);
  }

  75% {
    width: rem-calc(230);
    height: rem-calc(170);
  }
}

@keyframes shrink-floating-box {
  0% {
    width: rem-calc(190);
  }

  25% {
    width: rem-calc(125);
  }

  50% {
    width: rem-calc(80);
  }

  75% {
    width: rem-calc(62.5);
  }
}

.exapand-effect {
  animation: expand-floating-box 0.15s;
}

.shrink-effect {
  animation: shrink-floating-box 0.15s;
}

section#fixedArea {
  flex-direction: column;
  display: flex;
  gap: 20px;
  align-items: flex-end;
  position: fixed;
  right: 0;
  bottom: 20px;
  z-index:40;
  @media(max-width: 767px){
    &{
      bottom: 88px;
    }
  }
}