.notification {

	@include media(">=widescreen") {
		font-size: 1.1176em; // 19px
		line-height: 1.4737; // 28px
	}

	&.full-width {
		padding-left: rem-calc(20);
		padding-right: rem-calc(20);
		position: relative;
		z-index: 32;

		.container {
			padding-left: rem-calc(50);
			position: relative;

			@include media(">=fullhd") {
				padding-left: rem-calc(80);
			}
		}

		.delete {
			min-width: rem-calc(32);
			min-height: rem-calc(32);
			max-width: rem-calc(32);
			max-height: rem-calc(32);
			left: rem-calc(5);
			top: 50%;
			position: absolute;
			transform: translateY(-50%);

			@include media(">=fullhd") {
				left: rem-calc(30);
			}
		}
	}

	@media print {
		display: none !important;
	}
}

.notification-title {
	margin-right: rem-calc(10);
	letter-spacing: normal;
}

.fade-notification-enter-active, .fade-notification-leave-active {
	transition: all 0.25s ease-out;
}

.fade-notification-enter, .fade-notification-leave-to {
	opacity: 0;
	transform: translateY(-10px);
}
