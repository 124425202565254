.promo-flexible-carousel {
  .mask {
    overflow: hidden;
    margin: rem-calc(-40 -20);
    padding: rem-calc(40 10);
    position: relative;

    @include media(">=desktop") {
      margin-left: rem-calc(-38);
      margin-right: rem-calc(-38);
      padding-left: rem-calc(18);
      padding-right: rem-calc(18);
    }
  }

  .slides {
    display: flex;
  }

  .slide {
    display: flex;
    width: 100%;
    flex-basis: 100%;
    flex-shrink: 0;
    flex-grow: 1;
    padding-left: rem-calc(10);
    padding-right: rem-calc(10);

    @include media(">=tablet") {
      width: 50%;
      flex-basis: 50%;
    }

    @include media(">=desktop") {
      width: 33.33%;
      flex-basis: 33.33%;
      padding-left: rem-calc(20);
      padding-right: rem-calc(20);
    }
  }

  .control-buttons {
    .control-slide-button {
      padding: .5em;
      background-color: #ddd;
      border-radius: 50%;
      border: 0;

      &:hover {
        cursor: pointer;
        background-color: #999;
      }

      &:active,
      &:focus {
        border: 0;
        outline: 0;
      }
    }
  }
}
