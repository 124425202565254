.container-column {
  word-break: break-word;
  border-radius: 15px;

  &.has-background-white {
    &.is-outlined-white {
      .card-content {
        padding: 0 !important;
      }
    }
  }

  &:not(.has-background-white) {
    border-radius: 15px;
  }

  .card-content {
    .link-view {
      min-width: rem-calc(100);
    }
  }
}

.custom-columns {
  word-break: break-word;

  .blog-segment {
    border-bottom: 1px dashed #585858;
    padding-bottom: 1em;


    &-not-bottom-border{
      border-bottom: none;

      @include media('<=desktop') {
        border-bottom: 1px dashed #585858;
        padding-bottom: 1em;

      }
    }

    &.initial-column {
      @include media('<=desktop') {
        padding-top: 1em;
      }
    }
  }

  @include media('<=desktop'){
    flex-wrap: wrap;
  }
}

.block_columns {
  .custom-columns {
    ul {
      li {
        a {
          color: $blue !important;

          &:hover {
            color: $pink-dark !important;
          }
        }
      }
    }
  }
}
