// list reset
%listreset {
	margin: 0;
	padding: 0;
	list-style: none;
}

// clearfix
%clearfix {
	&:after {
		content: '';
		display: block;
		clear: both;
	}
}

.clearfix {
	@extend %clearfix;
}


// transition
%transition {
	transition: all .3s ease-in-out;
}

// justify nav
%justify {
	text-align: justify;
	font-size: 1px;
	line-height: 0px;
	> * {
		display: inline-block;
		vertical-align: top;
		text-align: left;
		// font-size: $font-size-base;
		// line-height: $line-height-base;
	}
	&:after {
		content: '';
		width: 100%;
		display: inline-block;
		vertical-align: top;
	}
}



/// * Helper class to truncate and add ellipsis to a string too long for it to fit
/// * on a single line.
/// * 1. Prevent content from wrapping, forcing it on a single line.
/// * 2. Add ellipsis at the end of the line.

.ellipsis {
	white-space: nowrap; /* 1 */
	text-overflow: ellipsis; /* 2 */
	overflow: hidden;
}

.force-full-width {
	//width: 100vw !important;

	@include media(">tablet"){
		margin-left: calc(-100vw / 2 + 1200px / 2) !important;
  	margin-right: calc(-100vw / 2 + 1200px / 2) !important;
	}
}

.is-flex-direction-sm-col {
	&.reverse-direction {
		@include media("<tablet") {
			flex-direction: column-reverse;
		}
	}

	@include media("<tablet") {
		flex-direction: column;
	}
}

.is-flex-direction-md-col {
	@include media("<desktop") {
		flex-direction: column;
	}
}

.flex-basis-half {
	flex-basis: 50%;
}

.image-xs {
	@include media('>=desktop'){
		width: 150px !important;
		height: auto !important;
	}
}

.image-sm {
	@include media('>=desktop') {
		width: 300px !important;
		height: auto !important;
	}
}

.image-md {
	@include media('>=desktop') {
		width: 400px !important;
		height: auto;
	}
}

.image-lg {
	@include media('>=desktop') {
		width: 600px !important;
		height: auto !important;
	}
}

.image-full-width {
	&:not(.side-column) {
		@include media('>=desktop') {
			width: 100%;
			height: auto;
		}
	}

	&.side-column {
		figure.image {
			@include media('>=desktop') {
				width: 100%;
				height: auto;
				margin: 0 auto;
			}
		}
	}
}

.image-fullscreen-width {
	width: 100vw !important;
	margin-left: calc((100% - 100vw) / 2);
}

// image-xl was deprecated..
.image-xl {
	@include media('>=desktop') {
		width: 800px !important;
		height: auto !important;
	}
}

.mx-auto {
	margin: 0 auto;
}

$start: 1;
$end: 100;

@for $i from $start to $end {
	.has-#{$i}-columns {
		column-count: #{$i} !important;
	}
}

.font-weight-500 {
	font-weight: 500;
}

.font-weight-600 {
	font-weight: 600 !important;
}

.opacity-0 {
	opacity: 0;
}

.opacity-1 {
	opacity: 1;
}

.cursor-pointer {
	cursor: pointer;
}

.visibility-state {
	display: none;
}

.c-block-preview, .c-block-preview--action-menu-visible {
	.visibility-state {
		display: block !important;
	}
}

$start: 1;
$end: 20;

@for $i from $start to $end {
	.rounded-corner-#{$i} {
		border-radius: #{$i}px;
	}

	.rounded-corner-top-#{$i} {
		border-top-left-radius: #{$i}px;
		border-top-right-radius: #{$i}px;
	}

	.rounded-corner-bottom-#{$i} {
		border-bottom-left-radius: #{$i}px;
		border-bottom-right-radius: #{$i}px;
	}

	.rounded-corner-left-#{$i} {
		border-top-left-radius: #{$i}px;
		border-bottom-left-radius: #{$i}px;
	}

	.rounded-corner-right-#{$i} {
		border-top-right-radius: #{$i}px;
		border-bottom-right-radius: #{$i}px;
	}

	.rounded-corner-top-left-#{$i} {
		border-top-left-radius: #{$i}px;
	}
}

[v-cloak] {
	opacity: 1;
	visibility: hidden;
}

.captcha_reload {
	display: block;
	font-size: small;
	margin-bottom: .5rem;
}

.block_featured_text {
	word-break: break-word;
}

.card {
	&.is-horizontal {
		&.has-background-white {
			.card-content {
				padding-top: 0;
				margin-top: 0;

				.content {
					padding-top: 0;
					margin-top: 0;
				}
			}
		}
	}
}

address {
	font-style: normal !important;
}

.borders-on-mobile {
	@include media("<=tablet"){
		border-radius: rem-calc(15);
	}
}

.m-sm-4 {
	@include media("<=tablet") {
		margin: rem-calc(15);
	}
}

@for $i from 1 to 5 {
	.ml-lg-#{ $i } {
		@include media(">=desktop") {
			margin-left: #{($i * .25)}rem;
		}
	}

	.mr-lg-#{ $i } {
		@include media(">=desktop") {
			margin-right: #{($i * .25)}rem;
		}
	}

	.-mt-#{ $i } {
		margin-top: -#{($i * .25)}rem;
	}
}

.full-width-none-desktop {
	@include media("<=desktop") {
		width: 100%;
	}
}

.help {
	&.is-danger {
		color: $pink !important;
	}

	&.is-success {
		color: $cyan-lighter !important;
	}
}

.ml-auto {
	margin-left: auto;
}

.text-orientation-up-right {
	writing-mode: vertical-lr;
	text-orientation: upright;
}

.py-only-mobile-1 {
	@include media("<tablet") {
		padding: {
			top: 0.25rem !important;
			bottom: 0.25rem !important;
		}
	}
}
