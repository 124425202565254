.topics-section {
  padding-top: rem-calc(29);
  padding-bottom: rem-calc(38);

  @include media(">=tablet") {
    padding-top: rem-calc(76);
    padding-bottom: rem-calc(72);
  }

  .section-heading {

    @include media(">=desktop") {
      padding-bottom: rem-calc(32);
    }
  }
}

.topics-buttons {
  word-break: break-word;
  align-items: stretch;

  @include media("<tablet") {
    margin-right: rem-calc(-3);
    margin-left: rem-calc(-3);
    width: calc(100% + 6px);
  }

  @include media(">=tablet") {
    flex-wrap: nowrap;
    box-shadow: 0 rem-calc(6) rem-calc(20) rgba($black, .15);
    border-radius: $radius-xlarge;
  }

  .button {
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    height: 100% !important;
    width: 100%;
    height: auto !important;
    min-height: rem-calc(94);
    padding: rem-calc(12);
    font-size: .7647em; // 13px
    line-height: 1.3077; // 17px
    white-space: normal;
    box-shadow: 0 0 rem-calc(15) rgba($black, 0.1);
    position: relative;

    @include media(">=phone") {
      width: calc(50% - 6px);
    }

    @include media("<tablet") {
      margin: rem-calc(3) !important;
      border-radius: rem-calc(12) !important;
      border: 0;
    }

    @include media(">=tablet") {
      align-items: flex-start;
      width: 25%;
      padding: rem-calc(20 15);
      font-size: .9412em; // 16px
      line-height: 1.0952;
      border-width: 0 1px 0 0;
      border-color: #d3d3d3;
      box-shadow: none;
      flex-grow: 1;
      margin: 0 !important;
    }

    @include media(">=desktop") {
      padding: rem-calc(28 25 23);
      font-size: 1.24em; // 21px
    }

    &:first-child {

      @include media(">=tablet") {
        border-bottom-left-radius: $radius-xlarge;
        border-top-left-radius: $radius-xlarge;
      }
    }

    &:last-child {

      @include media(">=tablet") {
        border-right: 0;
        border-bottom-right-radius: $radius-xlarge;
        border-top-right-radius: $radius-xlarge;
      }
    }

    &:hover {
      background-color: $link;

      span {
        color: $white !important;
        * {
          color: $white !important;
        }
      }
    }

    .icon {
      @include size(auto);
      font-size: .8462em; // 11px
      line-height: 1;
      margin-left: rem-calc(3) !important; // 3px
      margin-right: rem-calc(-3) !important; // -3px

      @include media(">=tablet") {
         font-size: .8095em; // 17px
         line-height: 1;
         top: -1px;
         position: relative;
      }
    }
  }
}

.block_topics_in_focus {
  .topics-buttons {
    a.button {
      &:only-child {
        @include media('<tablet') {
          width: calc(100% - 6px);
        }
      }
    }
  }
}
