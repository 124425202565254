.state-lookup {
  background-size: 100% auto !important;
  background-repeat: no-repeat !important;
  background-position: top center !important;
  padding: 2em;
  border-radius: 15px;
  width: 100%;
  min-height: 500px;

  .search-form {
    .input {
      background-color: $white;

      &:focus{
        border: $grey;
      }
    }
  }
}
