.promo-person {
  .card-content {
    .content {
      .title-promo-person {
        font-size: rem-calc(17);
        font-weight: 600;

        &:not(:hover) {
          color: $content-heading-color;
        }
      }
    }
  }

  .body-promo-person {
    font-size: 15px;
    line-height: 1.7em;
  }

  .subtitle-promo-person {
    font-weight: $strong-weight;
  }

  .description-promo-person {
    font-weight: 500;
  }
}
