#footer {
	font-size: rem-calc(15); // 15px
	line-height: 1.5333; // 23px

	@include media(">=tablet") {
		padding: rem-calc(70 20 54);
	}

	h6 {
		font-size: 1em; // 15px
		line-height: 1.334; // 20px
		min-height: 2.6667em;
		margin-bottom: 0.8em;
	}

	.box {
		padding: rem-calc(25 22);
		margin-bottom: rem-calc(21);
	}

	.btn-holder {
		margin-left: auto;

		.button {

			@include media("<phone") {
				padding-left: .8824em; // 15px
				padding-right: .8824em; // 15px
			}
		}
	}

	p {

		a {
			color: inherit;

			&:hover {
				color: $link;
			}
		}
	}
}

.add-nav-columns {

	> .column {

		@include media(">=tablet") {
			padding-bottom: rem-calc(8);
		}
	}
}

.add-nav {

	ul {
		font-size: rem-calc(14); // 14px
		line-height: 1.5; // 21
		letter-spacing: -.0143em; // -.2px

		> li {

			+ li {
				margin-top: rem-calc(7);
			}
		}
	}
}

.add-sub-nav {

	@include media(">=tablet") {
		margin-bottom: rem-calc(25);
	}

	ul {
		width: 100%;
		font-weight: $weight-medium;
		font-size: rem-calc(14);
		line-height: 1.5333; // 23px
		letter-spacing: -0.0133em; // -.2px

		@include media(">=phone") {
			font-size: rem-calc(18);
			line-height: 1.4444; // 26px
		}

		@include media("<tablet") {
			column-count: 2;
			column-gap: rem-calc(10);
			margin-bottom: 1em;
			& > li {
				padding-bottom: 16px!important;
			}
		}

		@include media(">=tablet") {
			font-size: rem-calc(15);
			line-height: 1.5333; // 26px
			font-weight: $weight-normal;
		}

		> li {
			break-inside: avoid;
			padding-bottom: rem-calc(2);

			@include media(">=tablet") {
				padding-bottom: rem-calc(10);
			}

			&:not(:last-child) {

				@include media(">=tablet") {
					padding-right: rem-calc(22);
				}
			}
		}
	}
}

.copyright {
	letter-spacing: -0.0133em; // -.2px

	@include media(">=tablet") {
		margin-bottom: rem-calc(20);
	}

	p {

		&:last-child {
			margin-bottom: 0;
		}
	}
}
