h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
	color: $content-heading-color;
	font-weight: $content-heading-weight;
	line-height: $content-heading-line-height;
	margin-bottom: 1em;
}

h1, .h1 {

	&,
	.content & {
		font-size: 2.3529em; // 40px
		line-height: .95;  // 38px
		letter-spacing: -.0175em; // -0.7px
		margin-bottom: 0.5em;

		@include media(">=desktop") {
			font-size: 2.9412em; // 50px
			line-height: 0.94; // 47px
			letter-spacing: -.014em; // -0.7px
		}

		&:not(:first-child) {
			margin-top: 1em;
		}
	}
}

h2, .h2 {

	&,
	.content & {
		font-size: 1.7647em; // 30px
		line-height: 1.2; // 36px
		letter-spacing: -0.0133em; // -.4px
		margin-bottom: 0.5714em;

		@include media(">=desktop") {
			font-size: 2.3529em; // 40px
			line-height: 1.2; // 48px
			letter-spacing: -0.01em; // -.4px
		}

		&:not(:first-child) {
			margin-top: 1.1428em;
		}
	}
}

h3, .h3 {

	&,
	.content & {
		font-size: 1.7647em; // 30px
		line-height: 1.2667; // 38px
		letter-spacing: -.0233em; // -.7px
		margin-bottom: 0.6666em;

		@include media(">=desktop") {
			font-size: 2.1176em; // 36px
			line-height: 1.0556; // 38px
			letter-spacing: -.0194em; // -.7px
		}

		&:not(:first-child) {
			margin-top: 1.333em;
		}
	}
}

h4, .h4 {

	&,
	.content & {
		font-size: 1.2941em; // 22px
		line-height: 1.1818; // 26px
		letter-spacing: -.03em; // -.5px
		margin-bottom: 0.8em;

		@include media(">=desktop") {
			font-size: 1.6471em; // 28px
			line-height: 1.1786; // 33px
			letter-spacing: -0.018em; // -.5px
		}
	}
}

h5, .h5 {

	&,
	.content & {
		font-size: 1.2941em; // 22px
		line-height: 1.3636; // 30px
		letter-spacing: -.03em; // -.5px
		margin-bottom: 0.8888em;
	}
}

h6, .h6 {

	&,
	.content & {
		font-size: 1.0588em; // 18px
		line-height: 1.6111; // 29px
		margin-bottom: 1em;
	}
}

p {

	&,
	.content & {

		&:not(:last-child) {
			margin-bottom: 1.1765em; // 20px

			@include media(">=desktop") {
				margin-bottom: 1.4118em; // 24px
			}
		}
	}
}

.lead {
	font-size: 1.0588em; // 18px
	line-height: 1.5; // 27px
}

a {
	@include animate(color border-color background box-shadow, .15s, ease-out);

	[class*="has-text-"] &,
	h1 &, .h1 &,
	h2 &, .h2 &,
	h3 &, .h3 &,
	h4 &, .h4 &,
	h5 &, .h5 &,
	h6 &, .h6 &, em, strong, i, b {

		&:not(.link-more):not(.button) {
			color: inherit;

			&:hover {
				color: $link;
			}
		}
	}

	[class*="has-text-"] p & {

		&:not(.link-more):not(.button) {
			color: $link;

			&:hover {
				color: $link-hover;
			}
		}
	}

	.has-background-primary.has-text-white & {

		&:not(.button) {

			&:hover {
				color: $dark;
			}
		}
	}

	.has-background-primary.has-text-white p & {

		&:not(.button) {
			color: $dark;
		}
	}
}

.link-more {
	display: inline-block;
	vertical-align: top;
	font-size: 0.9412em; // 16px
	line-height: 1.75;   // 28px
	font-weight: $weight-medium;
	color: $link;
	padding-right: rem-calc(27);
	position: relative;

	[class*="has-text-white"] & {
		color: $white;

		&:hover {
			color: $dark; //darken($white, 10%);
		}
	}

	&:hover {
		color: $link-hover;
	}

	&:after {
		content: $icon-arrow-right;
		font-size: 1em; // 16px
		line-height: 1;
		font-family: $icomoon-font-family;
		font-weight: $weight-normal;
		position: absolute;
		// right: 0;
		margin-top: .375em; // 6px
		margin-left: rem-calc(11);
	}

	&.link-external {

		&:after {
			content: $icon-external-link;
			font-size: 1.25em; // 20px
			line-height: 1;
			margin-top: 0;
		}
	}

	&.link-social {
		font-size: .7647em; // 13px
		line-height: 1.6923; // 22px
		letter-spacing: -.0308em; // -.4px
		padding-right: 0;

		.card & {
			letter-spacing: -.0308em; // -.4px
		}

		&:after {
			display: none;
		}

		.icon {
			@include size(auto);
			display: inline-block;
			margin-right: rem-calc(8);
			font-size: 1.5385em; // 20px
			line-height: 1;
		}
	}
}

.link-view {
	display: inline-block;
	vertical-align: top;
	font-size: 0.9412em; // 16px
	line-height: 1.25;   // 20px
	font-weight: $weight-medium;
	letter-spacing: -.0312em; // -.5px
	color: $primary;
	padding-right: rem-calc(24);
	position: relative;

	.section-heading & {
		margin-top: .3125em; // 5px
		margin-bottom: .3125em; // 5px

		@include media(">=desktop") {
			margin-top: 0;
			margin-bottom: 0;
		}
	}

	[class*="has-text-white"] & {
		color: $white;

		&:hover {
			color: darken($white, 10%);
		}
	}

	&:hover {
		color: $link;
	}

	&:after {
		content: $icon-chevron-right;
		font-size: 1em; // 16px
		line-height: 1;
		font-family: $icomoon-font-family;
		font-weight: $weight-normal;
		position: absolute;
		right: 0;
		margin-top: .125em; // 2px
	}
}

.load-more-link{
	display: inline-block;
	vertical-align: top;
  font-size: 18px;
  line-height: 1.666; // 30px
  color: $dark !important;
  font-weight: $weight-semibold;
  padding-bottom: rem-calc(42);
  position: relative;

  &:hover{
    color: $link !important;
  }

  &:after{
    content: $icon-chevron-down;
    font-family: $icomoon-font-family;
    font-weight: $weight-normal;
    font-size: rem-calc(45);
    line-height: 1;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

ol {
	list-style-position: inside;

	&,
	.content & {

		> li {

			+ li {
				margin-top: rem-calc(12);
			}
		}
	}
}

ul {
	&,
	.content & {
		list-style: none;
		padding: 0;
		margin: 0;

		&:not(:last-child) {
			margin: 0 0 1.1765em; // 20px

			@include media(">=desktop") {
				margin-bottom: 1.4118em; // 24px
			}
		}

		ul {
			list-style: none;
		}

		> li {
			position: relative;
			padding-left: rem-calc(22);

			+ li {
				margin-top: rem-calc(12);
			}

			&:before {
				@include size(rem-calc(7));
				content: "";
				position: absolute;
				left: 0;
				top: .5294em; // 9px
				border-radius: 50%;
				background: currentColor;
			}
		}

		.icon-download {
			@include size(auto);
			vertical-align: top;
			font-size: rem-calc(16);
			line-height: 1;
			margin: rem-calc(2 0 0 6);
		}

		&.has-bullets-dark {

			> li {

				&:before {
					background: $dark;
					color: $dark;
				}
			}
		}

		&.is-li-vertical-space-0 {

			> li {

				+ li {
					margin-top: 0;
				}
			}
		}

		&.is-li-vertical-space-small {

			> li {

				+ li {
					margin-top: .5rem;
				}
			}
		}

		&.is-bulletsless {

			&,
			ul {
				list-style: none;

				> li {

					&:before {
						display: none !important;
					}
				}
			}

			> li {
				padding-left: 0;
			}
		}

		&.list-unstyled {

			&,
			ul {
				list-style: none;

				> li {
					padding-left: 0;

					+ li {
						margin-top: 0;
					}

					&:before {
						display: none !important;
					}
				}
			}
		}

		&.sub-nav {
			font-size: 1.0588em; // 18px
			line-height: 1.6111; // 29px
			font-weight: $weight-semibold;
			letter-spacing: normal;

			&:not(:last-child) {
				margin-bottom: rem-calc(8);
			}

			&,
			ul {

				> li {

					&:before {
						@include size(auto);
						//content: $icon-chevron-right;
						background: transparent;
						border-radius: 0;
						font-size: rem-calc(19);
						line-height: 1;
						font-family: $icomoon-font-family;
						font-weight: $weight-normal;
						color: $dark;
						top: .1579em; // 3px
						left: rem-calc(-4);
					}

					+ li {
						margin-top: rem-calc(3);
					}
				}
			}

			ul {
				font-weight: $weight-medium;
				margin-top: rem-calc(3);
				padding-left: rem-calc(5);
			}
		}
	}
}

.downloads-list {
	letter-spacing: -.0294em; // -.5px

	&.is-small {
		font-size: rem-calc(13);
		//line-height: 2.1538; // 28px
		line-height: 24px;
		letter-spacing: normal;

		> li {

			+ li {
				margin-top: rem-calc(3) !important;
			}
		}

		.icon {
			top: .2308em; // 3px
		}
	}

	> li {

		+ li {
			margin-top: rem-calc(8) !important;
		}
	}

	a {
		display: inline-block;
		vertical-align: top;
		padding-left: rem-calc(36);
		position: relative;
	}

	.icon {
		@include size(auto);
		position: absolute;
		left: 0;
		top: .2em;
		font-size: rem-calc(16);
		line-height: 1;
		margin: 0 !important;

		&:hover {
			color: $link;
		}
	}
}

.items-list {
	font-size: $size-medium; // 16px
	line-height: 1.75; // 28px
}

.contacts-title {
	display: block;
	margin-bottom: rem-calc(5);

	.card-contact & {
		font-size: 1.0588em; // 18px
		line-height: 1.4444; // 18px/26px
		font-weight: $weight-bold;
		margin-bottom: rem-calc(3);
	}
}

.contacts-list {
	font-size: 1.0588em; // 18px
	line-height: 1.4444; // 18px/26px

	.card-contact & {
		font-size: 1em;
		line-height: 1.5294; // 17px/26px
	}

	> li {
		padding-left: rem-calc(27) !important;
		position: relative;

		+ li {
			margin-top: rem-calc(5) !important;
		}
	}

	a {
		display: inline-block;
		vertical-align: top;
	}

	.icon {
		@include size(auto);
		position: absolute;
		left: 0;
		top: rem-calc(3);
		font-size: rem-calc(20);
		line-height: 1;
		color: $dark;
	}
}

blockquote {

	&,
	.content & {
		position: relative;
		font-size: 1.1765em; //20px
		line-height: 1.3636; // 27px
		font-weight: $weight-bold;
		color: $dark;

		@include media(">=desktop") {
			font-size: 1.2941em; // 22px/ 30px
			padding-left: rem-calc(87);
		}

		&:not(:last-child) {
			margin-bottom: 1.2727em; // 28px
		}

		&:before {
			content: '“';
			font-size: 5.5em; // 110px
			line-height: 0.8889; // 160px
			font-weight: $weight-medium;
			color: currentColor;
			position: absolute;
			left: -0.0278em; // -5px
			top: -0.0167em; // -3px

			@include media(">=desktop") {
				font-size: 8.1818em; // 180px
			}
		}

		footer {
			@include media('<tablet') {
				font-size: rem-calc(13) !important;
			}
		}

		q {
			display: block;
			position: relative;
			padding-right: rem-calc(50);

			@include media('<tablet') {
				font-size: rem-calc(15);
			}

			@include media(">=desktop") {
				padding-right: rem-calc(100);
			}

			&,
			&:before,
			&:after {
				content: "";
				quotes: none;
			}

			&:after {
				content: '’’';
				display: inline-block;
				vertical-align: bottom;
				font-size: 5.5em; // 110px
				line-height: 0.8889; // 98px
				letter-spacing: -0.07em;
				font-weight: $weight-medium;
				color: currentColor;
				position: absolute;
				right: 0.09em; // 10px
				bottom: -.51em; // -56px

				@include media(">=desktop") {
					font-size: 8.1818em; // 180px / 160px
					right: 0.1389em; // 20px
					bottom: -.5278em; // -95px
				}
			}
		}

		cite,
		footer {
			display: block;
			font-size: 0.6818em; // 15px
			line-height: 1.7333; // 26px
			font-weight: $weight-normal;
			color: $text;
		}

		footer {

			&:first-child {
				padding-bottom: rem-calc(8);
				margin-bottom: rem-calc(15);
				border-bottom: 1px solid $grey-light;
			}

			&:last-of-type:not(:first-child) {
				padding-top: rem-calc(8);
				margin-top: rem-calc(15);
				border-top: 1px solid $grey-light;
			}
		}
	}
}
a {
	sup {
		color: inherit !important;
	}

	sub {
		color: inherit !important;
	}
}

.date {
	display: block;
	font-size: rem-calc(13); // 13px
	line-height: 1.8462; // 24px
	font-weight: $weight-medium;
	letter-spacing: -.0308em; // -0.4px
	color: $grey;

	.card-news & {
		line-height: 1.6923; // 22px
	}
}

.category-label {
	display: inline-block;
	vertical-align: top;
	font-size: rem-calc(12); // 12px
	line-height: 1.8333; // 22px
	font-weight: $weight-medium;
	text-transform: uppercase;
	letter-spacing: rem-calc(2);
}

.made-by {
	display: inline-block;
	vertical-align: top;
	font-size: $size-small; // 12px
	line-height: 1.6667; // 20px
	letter-spacing: -.0417em; // -.5px
	font-weight: $weight-normal;
	font-style: normal !important;
	color: $grey;

	.has-text-white & {
		opacity: .6;
		color: $white;
	}
}

.author {
	display: block;
	font-size: rem-calc(16);
	line-height: 1.25; // 20px
	letter-spacing: -0.0312em; // -.5px
	margin-bottom: rem-calc(15);
}

.search-result {
	display: block;
	font-size: rem-calc(18);
	line-height: 1.6667; // 30px
	margin-bottom: rem-calc(3);
}

.link-account {
	display: inline-block;
	vertical-align: top;
	font-size: .7647em; // 13px
	line-height: 1.6923; // 22px
	font-weight: $weight-medium;
	letter-spacing: -.0308em; // -.4px

	&[class*="has-text-"] {

			&:not(.link-more):not(.button) {

			&:hover {
				color: $link !important;
			}
		}
	}
}

figcaption.made-by {
	padding-top: rem-calc(8);
}

ol {
	li {
		&:last-child {
			padding-bottom: rem-calc(20);
		}
	}
}

.block-richtext {
	h6 {
		margin-bottom: .25em;
	}
}

.page-content-bullets ul > li {
    list-style: none;
    position: relative;
}

.page-content-bullets ul > li::before {
    display: none;
}

.page-content-bullets ul > li::after {
    content: '';
    position: absolute;
    width: 1em;
    aspect-ratio: 1;
    background: #D62C77;
    left: 0;
    top: 0.25em;
    clip-path: circle(16%);
}

.page-content-bullets .type-bullet-check ul > li::after {
    clip-path: polygon(92.081% 14.022%, 32.612% 70.248%, 7.775% 47.462%, 0% 55.92%, 28.799% 82.345%, 32.756% 85.978%, 36.646% 82.305%, 100% 22.409%, 92.081% 14.022%);
}
.page-content-bullets ul > li > ul > li::after {
    clip-path: polygon(15% 45%, 85% 45%, 85% 55%, 15% 55%);
}
.page-content-bullets ul > li > ul > li > ul > li::after {
    clip-path: polygon(34% 34%, 66% 34%, 66% 66%, 34% 66%);
}

.page-content-bullets ul.hidden-bullets > li::after {
    display: none;
}