$default-column-items-count: 3;
$narrow-column-items-count: 2;
$mobile-coulumn-items-count: 1;

.members-list {
  column-count: $default-column-items-count;
  word-break: break-word;

  @for $i from 1 through 4 {
    @at-root
    .columns .column.is-#{$i} &,
    .columns .column.is-#{$i}-desktop &{
      column-count: 1;
    }
  }

  @at-root
  .columns .column.is-5 &,
  .columns .column.is-5-desktop &{
    column-count: $narrow-column-items-count;

    @include mobile {
      column-count: $mobile-coulumn-items-count;
    }
  }

  //  For nested columns
  @at-root
  .columns .column .columns & {
    column-count: $narrow-column-items-count;
  }

  @at-root
  .columns .column.is-8 .columns .column.is-6 &,
  .columns .column.is-8-desktop .columns .column.is-6-desktop & {
    column-count: 1;
  }

  @at-root
    .columns .column.is-12-desktop &,
    .columns .column.is-12 {
      @include mobile {
        column-count: $mobile-coulumn-items-count;
      }
    }

  @include mobile {
    column-count: $mobile-coulumn-items-count;
  }
}

.block_member_list {
  .card.is-shadowless.is-outlined-white.has-background-white {
    .card-content {
      padding: 0px;
    }
  }
}

.special-nav-full-width {
  ul {
    li {
      word-break: break-word;
    }
  }
}
