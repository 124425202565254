@charset "utf-8";

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,400;0,500;0,600;1,100;1,200;1,400;1,500;1,600&display=swap');

$breakpoints: (
	'xs-phone': 320px,
	'phone': 375px,
	'xl-phone': 576px,
	'tablet': 768px,
	'xl-tablet': 900px,
	'desktop': 1024px,
	'widescreen': 1216px,
	'fullhd': 1280px,
	'xl-fullhd': 1400px
);

// keywords
$media-expressions: (
	'screen': 'screen',
	'print': 'print',
	'handheld': 'handheld',
	'landscape': '(orientation: landscape)',
	'portrait': '(orientation: portrait)',
	'retina2x': '(-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi)',
	'retina3x': '(-webkit-min-device-pixel-ratio: 2.5), (min-resolution: 240dpi)'
);

@import 'vendors/icomoon';

// Customization

// You can easily customize Bulma with your own variables.
// Just uncomment the following block to see the result.

// 1. Import the initial variables
@import "../../node_modules/bulma/sass/utilities/initial-variables";

/*
// 2. Set your own initial variables
// Update the blue shade, used for links
$blue: #06bcef;
// Add pink and its invert
$pink: #ff8080;
$pink-invert: #fff;
// Update the sans-serif font family
$family-sans-serif: "Helvetica", "Arial", sans-serif;

// 3. Set the derived variables
// Use the new pink as the primary color
$primary: $pink;
$primary-invert: $pink-invert;
*/

@import "bulma-custom";

// 4. Import the rest of Bulma
@import "../../node_modules/bulma/bulma";
@import "../../node_modules/swiper/swiper-bundle";
@import "../../node_modules/vue-select/dist/vue-select.css";
@import "../../node_modules/@mathieustan/vue-datepicker/dist/vue-datepicker.min.css";

// ligatured operators ≥ ≤

@import 'vendors/include-media';
@import 'vendors/modules';
@import 'vendors/admin-pagebuilder';

@import 'base/functions';
@import 'base/mixins';
@import 'base/helpers';
@import 'base/typography';
@import 'base/forms';
@import 'base/custom-icons';

@import 'layout/common';
@import 'layout/header';
@import 'layout/footer';

@import 'components/buttons';
@import 'components/buttons-list';
@import 'components/topics-buttons';
@import 'components/tags';
@import 'components/social-media';
@import 'components/subscribe-form';
@import 'components/search-form';
@import 'components/cards';
@import 'components/aside-filter-card';
@import 'components/notifications';
@import 'components/hero-carousel';
@import 'components/news-carousel';
@import 'components/posts';
@import 'components/percents-block';
@import 'components/progress-bars';
@import 'components/accordion';
@import 'components/video-block';
@import 'components/state_lookup';
@import 'components/promo-person';
@import 'components/columns';
@import 'components/filter-news';
@import 'components/aside-news';
@import 'components/carousel';
@import 'components/resources-section';
@import 'components/slide-promo-flexible';
@import 'components/spinner';
@import 'components/placeholder-js-block';
@import 'components/contacts-block';
@import 'components/skeletons';
@import 'components/members-list';
@import 'components/cookie-notification';
@import 'components/secondary-navbar';
@import 'components/searchBar.scss';
@import 'components/sticky.scss';
@import 'components/gallery.scss';
@import 'components/breadcrumbs.scss';
@import 'components/form.scss';
@import 'base/tables';
@import "print";
