.transition-right-to-left {
  @keyframes moveRightToLeft {
    0% {
      display: none;
      position: relative;
      left: 8px;
      opacity: .4;
    }
    25% {
      position: relative;
      left: 6px;
      opacity: .6;
    }
    50% {
      position: relative;
      left: 4px;
      opacity: .8;
    }
    100%{
      position: relative;
      left: 0px;
      opacity: 1;
    }
  }
  animation: moveRightToLeft .7s ease-out;
}

.slides-promo-flexible {
  .controls-for-slide{
    &.top-orientation {
      position:absolute;
      bottom: rem-calc(25);
      right: rem-calc(25);
    }

    &.horizontal-orientation {
      position: absolute;
      bottom: rem-calc(25);
    }

    &.to-left {
      right: rem-calc(25);
    }

    &.to-right {
      @include media(">=desktop") {
        right: 50%;
        margin-right: rem-calc(25);
      }

      @include media("<desktop") {
        right: rem-calc(25);
      }
    }

    .button-slide {
      padding: .5em;
      background-color: $grey-light-alt;
      border-radius: 50%;
      border: 0;
      margin-left: 1px;
      margin-right: 1px;

      &:hover {
        cursor: pointer;
        background-color: $grey-dark;
      }

      &:active,
      &:focus {
        border: 0;
        outline: 0;
        background-color: $pink;
      }
    }

    .button-slide-high-contrast {
      padding: .5em;
      background-color: $grey-light-alt;
      border-radius: 50%;
      border: 0;
      margin-left: 1px;
      margin-right: 1px;

      &:hover {
        cursor: pointer;
        background-color: $grey-dark;
      }

      &:active,
      &:focus {
        border: 0;
        outline: 0;
        background-color: $white;
      }
    }

    .active-button-slide {
      background: $pink;
    }

    .active-button-slide-high-contrast {
      background: $white;
    }
  }
}
