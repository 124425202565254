.swiper {
  overflow: hidden !important;
}
.slideshow {
  display: flex;
  flex-direction: column;
  gap: 25px;
  img {
    width: 100%;
  }
  & > * {
    width: 100%;
  }
  .swiper:hover {
  .swiper-arrows:not(.swiper-button-disabled) {
    opacity: 1;
  }
  }
  .swiper-arrows {
    color: #fff;
    font-size: 20px;
    height: auto;
    width: 2.5em;
    aspect-ratio: 1;
    top: 50%;
    margin: 0;
    transform: translateY(-50%);
    font-weight: 700;
    z-index: 10;
    background: #00000066;
    transition: all ease 0.4s;
    opacity: 0;
    &::after {
      position: absolute;
      inset: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &.swiper-button-prev {
      left: 0;
    }
    &.swiper-button-next {
      right: 0;
    }
    &:hover {
      &::before {
        opacity: 1;
      }
    }
    &.swiper-button-disabled {
      opacity: 0;
    }
  }
  .thumbnails {
    .swiper-arrows {
      font-size: 13px;
      bottom: 0;
      padding-top: 0;
      &::before {
        opacity: 1;
      }
    }
  }

  .swiper-arrows::after {
    font-size: inherit;
  }
  .thumbnails {
    .swiper-slide {
      cursor: pointer;
      filter: grayscale(1);
      opacity: 0.4;
      transition: all ease 0.4s;
      &:hover,
      &.swiper-slide-thumb-active {
        filter: grayscale(0);
        opacity: 1;
      }
    }
  }
}

.gallery {
  $gallery: &;
  &-wrapper {
    display: flex;
    .column.is-12 {
      width: 100%;
      flex-basis: 100%;
    }
    .is-4-desktop & {
      #{$gallery}-item {
        width: 100%;
        flex-basis: 100%;
      }
    }
    .is-6-desktop & {
      #{$gallery}-item {
        width: 50%;
        flex-basis: 50%;
      }
    }
    .is-8-desktop & {
      #{$gallery}-item {
        width: 50%;
        flex-basis: 50%;
      }
    }
    .is-12-desktop & {
      #{$gallery}-item {
        width: 33.3333%;
        flex-basis: 33.3333%;
      }
    }
    @media (max-width: 767px) {
      [class*="is-"] & {
        #{$gallery}-item {
          width: 50%;
          flex-basis: 50%;
        }
      }
    }
  }
  &-image {
    width: 100%;
    position: relative;
    padding-top: 61.3%;
    img {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      aspect-ratio: 862/531;
      object-position: center;
      object-fit: contain;
    }
    &-wrapper {
      position: relative;
    }
    &-description {
      color: #fff;
      position: absolute;
      width: 100%;
      left: 0;
      top: 0;
      margin-top: 61.3%;
      transform: translateY(-100%);
      padding: 20px;
      box-sizing: border-box;
      display: block;
      @media (max-width: 767px) {
        & {
          padding: 10px;
          font-size: 14px;
        }
      }
    }
    &-caption {
      margin-top: 8px;
      font-size: 12px;
      color: #757575;
    }
  }
}
