$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "../fonts" !default;

$icon-search: "\e900";
$icon-user: "\e901";
$icon-calendar: "\e902";
$icon-external-link: "\e904";
$icon-filter: "\e905";
$icon-phone: "\e907";
$icon-print: "\e908";
$icon-rss: "\e909";
$icon-mail: "\e90a";
$icon-linkedin: "\e90b";
$icon-facebook: "\e90c";
$icon-twitter: "\e90d";
$icon-download: "\e90e";
$icon-chevron-down: "\e90f";
$icon-chevron-up: "\e910";
$icon-chevron-left: "\e911";
$icon-chevron-right: "\e912";
$icon-arrow-down: "\e913";
$icon-arrow-up: "\e914";
$icon-arrow-left: "\e915";
$icon-arrow-right: "\e916";
$icon-folders: "\e917";
$icon-book: "\e916";
$icon-search-thick: "\e919";
$icon-close: "\e91a";
$icon-burger-dots: "\e91b";
$icon-youtube: "\e91c";



$icon-chevron-medium-down: "\e91a";
$icon-chevron-medium-up: "\e91b";
$icon-chevron-medium-left: "\e91m";
$icon-chevron-medium-right: "\e91d";
