.progress-bars {
	width: 100%;
	padding-bottom: rem-calc(15);

	h5 {
		letter-spacing: normal;
		margin-bottom: .5455em; // 12px
	}
}

.progress-bar {
	position: relative;
	margin-bottom: rem-calc(12);

	label,
	.label {
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		max-width: 100%;
		padding: rem-calc(0 10);
		font-size: rem-calc(13);
		line-height: 1.3077; // 17px
		letter-spacing: 2px;
		font-weight: $weight-semibold;
		text-transform: uppercase;
		color: $white;
		margin-bottom: 0;

		@include media(">=phone") {
			padding: rem-calc(0 17);
			letter-spacing: 3px;
		}

		@include media(">=tablet") {
			padding: rem-calc(0 10);
			letter-spacing: 2px;
		}

		@include media(">=fullhd") {
			letter-spacing: 3px;
			padding: rem-calc(0 17);
		}
	}
}

.progress {

	&.is-large {
		height: rem-calc(34);
	}

	&::-webkit-progress-value {
		border-radius: $radius-rounded;
		overflow: hidden;
	}

  &::-moz-progress-bar {
  	border-radius: $radius-rounded;
		overflow: hidden;
  }
	
	&::-ms-fill {
		border-radius: $radius-rounded;
		overflow: hidden;
	}
}

.progress-desc-list {
	font-size: rem-calc(12);
	line-height: 1.5833; // 19px
	color: $grey;
	display: flex;
	flex-wrap: wrap;
	white-space: nowrap;
	margin-right: rem-calc(-10);
	width: calc(100% + 10px);

	@include media(">=phone") {
		margin-right: rem-calc(-17);
		width: calc(100% + 17px);
	}

	@include media(">=tablet") {
		margin-right: rem-calc(-10);
		width: calc(100% + 10px);
	}

	@include media(">=fullhd") {
		margin-right: rem-calc(-17);
		width: calc(100% + 17px);
	}

	> li {
		padding-left: rem-calc(20);
		margin: rem-calc(12 10 0 0);

		@include media(">=phone") {
			margin-right: rem-calc(17);
		}

		@include media(">=tablet") {
			margin-right: rem-calc(17);
		}

		@include media(">=fullhd") {
			margin-right: rem-calc(17);
		}

		&:before {
			top: rem-calc(4);
		}

		&.is-info {

			&:before {
				background: $info;
			}
		} 

		&.is-link {

			&:before {
				background: $link;
			}
		} 

		&.is-primary {

			&:before {
				background: $primary;
			}
		}

		&.is-dark {

			&:before {
				background: $dark;
			}
		}

		&.is-success {

			&:before {
				background: $success;
			}
		}

		&.is-danger {

			&:before {
				background: $danger;
			}
		}

		&.is-warning {

			&:before {
				background: $warning;
			}
		}

		&.is-black {

			&:before {
				background: $black;
			}
		}

		&.is-white {

			&:before {
				background: $white;
			}
		}

		&:before {

			&,
			.content & {
				@include size(rem-calc(11));
				top: .3333em; // 4px 
			}
		}
	}
}