* {
  scroll-behavior: smooth;
}

.section{
  &-sticky{
    [data-sticky]{
      position: relative;
      padding: 40px 0;
      .bg-overlay{
        content: "";
        position: absolute;
        top:0;
        left: 50%;
        border: 0 solid #52A4AF;
        height: 100%;
        width: calc(100% + 40px);
        transition: all 0.4s ease;
        transform: translateX(-50%);
        &.has-background-white{
          border-top-width: 1px;
          border-bottom-width: 1px;
        }
        &[class*="has-background"]:not(.has-background-white){
          width: 100vw;
        }
      }
      .section-heading {
        padding: 0 20px 20px;
        h4 {
          font-size: 22px;

        }
      }
    }
    [data-sticky="true"] {
      z-index:20;
    }
    &-span {
      [data-sticky]{
        .bg-overlay{
          width: 100vw;
          border-top-width: 0px!important;
          border-bottom-width: 2px!important;
        }
      }
    }
    &-wrapper{
      [data-sticky]{
        position:fixed;
        top:0;
        left:0;
        right: 0;
        @media(max-width:767px){
          position: relative;
        }
      }
    }
  }

}
.nav-menu {
  &-wrapper {    
    font-size: 15px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-gap: 8px 0;
    @media(min-width: 768px){
      grid-auto-flow: column;
    }
    gap:8px 0;
    padding: 0;
    margin: 0;
    list-style: none;
    --cols: 3;
    @media(max-width:767px){
      --cols: 1;  
    }
  }
  &-item{
    margin:0;
    padding:0;
    width: 100%;
    &:first-child:nth-last-child(4), &:first-child:nth-last-child(2),
    &:first-child:nth-last-child(4) ~ li, &:first-child:nth-last-child(2) ~ li {
        --cols: 2;
        @media(max-width:767px){
          --cols: 1;  
        }
    }
    &::before{
      display:none;
    }
  }
  &-link {
    color: $blue;
    font-weight: 600;
    padding:0 20px;
    position: relative;
    display:block;
    trasnition: color 0.4s ease;
    &::after {
      content: "";
      display:block;
      position: absolute;
      left:0;
      bottom:0;
      height: 0%;
      width: 5px;
      background: $pink;
      transition: height 0.4s ease;
    }
    &:hover {
      color:$pink;
      &::after {
        height: 100%;
      }
    }
  }
}