.buttons-list {
	display: flex;
	flex-wrap: wrap;
	word-break: break-word;

	li {
		a {
			overflow: hidden;
		}
	}

	&,
	.content & {
		margin-left: rem-calc(-14);
		margin-right: rem-calc(-14);
		width: calc(100% + 28px);

		@include media(">=desktop") {
			margin-left: rem-calc(-17);
			margin-right: rem-calc(-17);
			width: calc(100% + 34px);
		}

		> li {
			padding: rem-calc(0 14 22);

			@include media(">=desktop") {
				padding: rem-calc(0 17 16);
			}

			+ li {
				margin-top: 0 !important;
			}
		}
	}

	.aside-column h5 + & {

		@include media(">=desktop") {
			padding-top: rem-calc(9);
			padding-bottom: rem-calc(9);
		}
	}

	&.one-column-desktop {
		> li {

			@include media(">=desktop") {
				width: 100%;
				flex-basis: 100%;
			}

			@include media(">=widescreen") {
				width: 100%;
				flex-basis: 100%;
			}

			@include media(">tablet"){
				width: 100%;
				flex-basis: 100%;
			}

			@include media("<=tablet"){
				width: 50%;
				flex-basis: 50%;
			}
		}
	}

	&.two-columns-desktop {

		> li {

			@include media(">=desktop") {
				width: 50%;
				flex-basis: 50%;
			}

			@include media(">=widescreen") {
				width: 50%;
				flex-basis: 50%;
			}

			@include media("<=tablet"){
				width: 50%;
				flex-basis: 50%;
			}
		}
	}

	&.three-columns-desktop {
		> li {

			@include media(">=desktop") {
				width: 33%;
				flex-basis: 33%;
			}

			@include media(">=widescreen") {
				width: 33%;
				flex-basis: 33%;
			}
		}
	}

	&.four-columns-desktop {
		> li {

			@include media(">=desktop") {
				width: 25%;
				flex-basis: 25%;
			}

			@include media(">=widescreen") {
				width: 25%;
				flex-basis: 25%;
			}
		}
	}

	&.five-columns-desktop {
		> li {
			@include desktop {
				width: 25%;
				flex-basis: 25%;
			}

			@include media(">=widescreen") {
				width: 20%;
				flex-basis: 20%;
			}
		}
	}

	> li {
		width: 50%;
		flex-basis: 50%;
		text-align: center;


		@include media(">=414px") {
			width: 50%;
			flex-basis: 50%;
		}

		@include media(">=xl-phone") {
			width: 25%;
			flex-basis: 25%;
		}

		@include media(">=tablet") {
			width: 33.33%;
			flex-basis: 33.33%;
		}

		@include media(">=desktop") {
			width: 25%;
			flex-basis: 25%;
		}

		@include media(">=widescreen") {
			width: 20%;
			flex-basis: 20%;
		}

		&:before {
			display: none;
		}
	}

	.button {
		min-width: rem-calc(106);
		padding-left: rem-calc(5) !important;
		padding-right: rem-calc(5) !important;
		display: block;
		letter-spacing: -0.01em;

		@include media(">=desktop") {
      min-width: rem-calc(130);
      letter-spacing: 0.05em;
      padding-left: rem-calc(10) !important;
			padding-right: rem-calc(10) !important;
    }

		&:not(:last-child) {
			margin-bottom: rem-calc(8);
		}
	}

	.desc {
		display: block;
		font-size: .8235em; // 14px
		line-height: 1.4286; // 20px
		min-height: rem-calc(40);
	}

	&.is-small {
		display: block;

		@include media(">=414px") {
			//column-count: 1;
			column-gap: rem-calc(20);
		}

		@include media(">=desktop") {
			column-count: 2;
		}

		@include media("<desktop") {
			column-count: 1;
		}

		.aside-column & {

			@include media(">=tablet") {
				column-count: 1;
			}

			@include media(">=widescreen") {
				column-count: 2;
			}
		}

		&,
		.content & {
			margin-left: 0;
			margin-right: 0;
			width: 100%;

			@include media(">=desktop") {
				margin-left: 0;
				margin-right: 0;
				width: 100%;
			}

			> li {
				padding: rem-calc(0 0 13);

				@include media(">=desktop") {
					padding: rem-calc(0 0 13);
				}
			}
		}

		> li {
			display: flex;
			align-items: center;
			text-align: left;
			width: 100% !important;
			break-inside: avoid;
		}

		.button {
			min-width: rem-calc(49);
			letter-spacing: normal;
			margin: rem-calc(0 16 0 0);
			flex-basis: auto;
			flex-shrink: 0;
			font-size: rem-calc(13);
			line-height: 1.31 !important; // 17px
			height: 2.8462em !important; // 37px
			padding: .6923em rem-calc(2) !important;

			@include media(">=desktop") {
	      min-width: rem-calc(49);
	      letter-spacing: normal;
	      padding-left: rem-calc(3) !important;
				padding-right: rem-calc(3) !important;
	    }

			&:not(:last-child) {
				margin-bottom: 0;
			}
		}

		.desc {
			font-size: .7647em; // 13px
			line-height: 1.3846; // 18px
			min-height: 1px;
			flex-grow: 1;
		}
	}

	.variation-high-light {
		width: 50% !important;
		flex-basis: 50% !important;
	}
}

.list-products {
	li {
		@include media('>=tablet') {
			margin-left: 1em;
		}
	}
}

.navigation-list-feature {
	padding-left: 0 !important;
	margin-left: 0;

	li {
		padding-left: 0 !important;

		&:before {
			background: transparent !important;
		}
	}

	.simple-nav-item {
		padding-left: 20px !important;
	}

	.nav-child{
		padding-left: 40px;
	}
}

.rotate-quarter {
	@include animate(transform color, .15s, ease-out);
	transform:  rotate(90deg);
	color: $pink;
}

.column.is-8,
.column.is-8-tablet {
	ul.buttons-list.four-columns-desktop {
		li {
			@include desktop {
				width: 25%;
				flex-basis: 25%;
			}

			@include media(">=widescreen") {
				width: 20% !important;
				flex-basis: 20% !important;
			}
		}
	}
}

.column.is-12,
.column.is-12-desktop {
	ul.buttons-list.five-columns-desktop {
		li {
			@include media(">=desktop") {
				width: 16.6% !important;
				flex-basis: 16.6% !important;
			}
		}
	}
}

aside.column {
	&.is-4, &.is-4-desktop {
		&.is-pulled-left, &.is-pulled-right {
			.block_navigator_highlight {
				.column.is-5-desktop {
					.content {
						.buttons-list.one-column-desktop.is-small {
							column-count: 1 !important;
						}
					}
				}
			}
		}
	}
}
