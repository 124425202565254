@media print {
  header {
    .navbar-top.is-hidden-touch.is-flex {
      height: 0;
    }
  }

  main {
    &#main {
      > .section {
        padding-top: 0;
      }
    }
  }

  .hide-for-print {
    display: none !important;
  }

  .remove-shadow-for-print {
    box-shadow: none !important;
  }

  .is-12-print {
    width: 100% !important;
  }

  a {
    text-decoration: underline !important;
  }

  h1, h2, h3, h4, h5, h6, .title, .content p {
    color: #000 !important;
  }

  .navbar {
    box-shadow: none !important;
  }

  .navbar-menu-holder {
    display: none !important;
  }

  .card-contact {
    display: none !important;
  }

  .social-media, .share-article-media {
    display: none !important;
  }

  .show-on-focus {
    display: none !important;
  }

  .link-more::after {
    content: none;
  }

  .block_news_carousel, .block_video_embed, .block_video_upload {
    display: none;
  }

  .footer {
    font-size: rem-calc(12) !important;

    .copyright {
      display: none !important;
    }
  }
}
