.form-card {
  $card: &;
  position: relative;
  @media (min-width: 768px) {
    margin-bottom: -20px;
  }
  z-index: 10;
  .column {
    padding: 0.625rem !important;
  }
  .columns {
    margin: -0.625rem !important;
  }
  button {
    border: none;
    background: none;
    padding: 0;
  }
  &-wrapper {
    width: 260px;
    background: white;
    border-radius: 10px 0 0 0;
    box-shadow: 0 0.625rem 1.25rem rgba(0, 0, 0, 0.1);
    padding: 1.375rem;
    display: grid;
    grid-template-rows: auto 0fr;
    transition: all ease 0.6s;
    :not(.select) > select:not([multiple]) {
      height: auto;
      padding: 0.4em 1em;
    }
    #{$card}-icon {
      display: grid;
      align-items: center;
      grid-template-columns: 0.825em 1.4em;
      gap: 12px;
      margin-left: auto;
      cursor: pointer;
      .arrow {
        color: #d62c77;
        fill: none;
        transform: rotate(-90deg);
        transition: all ease 0.6s;
      }
      * {
        display: block;
      }
    }
    &.open {
      grid-template-rows: auto 1fr;
      pointer-events: auto;
      #{$card}-icon {
        .arrow {
          transform: rotate(90deg);
        }
      }
    }
    button[type="submit"] {
      width: 100%;
      background-color: #429aa6;
      border: none;
      cursor: pointer;
      justify-content: center;
      text-align: center;
      white-space: nowrap;
      border-radius: 8px;
      height: auto !important;
      line-height: 1;
      font-size: 0.9em;
      padding: 0.8em 1.6em;
    }
    form label + div {
      display: flex;
      flex-wrap: wrap;
      gap: 15px;

      label {
        margin-left: 1.5em;
        position: relative;
        padding-left: 0.4em;
        cursor: pointer;
        color: #0b5793;
      }

      label::before {
        content: "";
        display: block;
        width: 0.7em;
        aspect-ratio: 1;
        position: absolute;
        right: 100%;
        margin: 0.4em;
        border-radius: 100%;
        outline: 1px solid currentColor;
        outline-offset: 2px;
      }

      input:checked + label::before {
        background: #429aa6;
      }

      input {
        display: none;
      }
    }
  }
  &-title {
    text-transform: uppercase;
    letter-spacing: 0.2em;
    color: #4fa3ae;
    line-height: 1;
    font-size: 12px;
    font-weight: 500;
  }

  &-title-wrapper {
    display: flex;
    align-items: center;
    gap: 20px;
    font-size: 16px;
  }
  &-content {
    padding-top: 12px;
  }
  &-content-wrapper {
    position: relative;
    overflow: hidden;
    .overlay {
      position: absolute;
      inset: -5px;
      z-index: 1;
      backdrop-filter: blur(2px) grayscale(0.5);
    }
    .spin {
      display: block;
      width: 40px;
      height: 40px;
      background: #429aa6;
      margin: auto;
      position: absolute;
      inset: 0;
      z-index: 1;
      border-radius: 100%;
    }
    .overlay,
    .spin {
      transition: all ease 0.4s;
      opacity: 0;
      pointer-events: none;
    }
    .message {
      transition: opacity ease 0.4s;
      border: 1px solid currentColor;
      font-size: 0.8em;
      padding: 1em;
      border-radius: 8px;
      bottom: 0;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      &.error {
        background: #ffe8e8;
        color: red;
      }
      &.success {
        position: static;
        background: none;
        text-align: center;
        border: none;
        position: static;
        font-size: 16px;
      }
      &.open {
        opacity: 1;
        pointer-events: auto;
      }
    }

    &.loading {
      .spin {
        animation: ping 0.6s ease-in-out infinite both;
      }
      .overlay,
      .spin {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
  .control {
    width: 100%;
  }
}
@-webkit-keyframes ping {
  0% {
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
    opacity: 0.8;
  }
  80% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(2.2);
    transform: scale(2.2);
    opacity: 0;
  }
}
@keyframes ping {
  0% {
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
    opacity: 0.8;
  }
  80% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(2.2);
    transform: scale(2.2);
    opacity: 0;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
