.accordion {

	h2 + &,.h2 + &,
	h3 + &,.h3 + &,
	h4 + &,.h4 + &,
	h5 + &,.h5 + &,
	h6 + &,.h6 + & {
		margin-top: rem-calc(-18);
	}

	.accordion-item {
		border-bottom: 1px solid $grey-lighter;

		&:first-child {

			h2 + &,.h2 + &,
			h3 + &,.h3 + &,
			h4 + &,.h4 + &,
			h5 + &,.h5 + & {

				.opener {
					// padding-top: 0;
				}
			}
		}

		> h6 {
			font-size: 1em; // 17px
			line-height: 1.4118; // 24px
			font-weight: $weight-semibold;
			letter-spacing: -.0294em; // -.5px
			margin-bottom: 0;
		}

		&.is-active {

			.opener {

				&:after {
					transform: translateY(-50%) rotate(90deg);
				}
			}
		}
	}

	&-title {
		padding-right: rem-calc(24);
	}

	.opener {
		display: flex;
		width: 100%;
		min-height: rem-calc(67);
		font-size: 1em; // 17px
		line-height: 1.4118; // 24px
		font-weight: $weight-semibold;
		padding: rem-calc(22 24 18 0);

		position: relative;

		&:after {
			font-size: rem-calc(38);
			font-weight: $weight-normal;
			font-family: $icomoon-font-family;
			color: $primary-lighter;
			position: absolute;
			right: rem-calc(7);
			top: 50%;
			transform: translateY(-50%);
		}

		.icon {
			float: right;
			border: 0;
			margin-left: auto;
		}
	}
}
