.cookie-notification {
  position: fixed;
  bottom: 0;
  z-index: 30;
  width: 100%;
  background-color: $primary;
  color: $white;
  padding: rem-calc(22) rem-calc(20);

  .container {
    display: flex;
    justify-content: space-between;
  }

  &__content {
    font-size: rem-calc(15);
    color: currentColor;
    font-weight: 600;
    padding-right: rem-calc(20);

    a {
      color: currentColor;
      text-decoration: underline;
    }
  }

  &__actions {}

  @include desktop {
    &__content {
      font-size: rem-calc(17);
    }
  }

  @media print {
    display: none !important;
  }
}
