a.tag {

	&:hover {
		text-decoration: none;
	}
}

.tag {
	height: auto !important;
  white-space: unset !important;
  width: auto;
	max-width: 100%;

	&:not(body) {
		line-height: 1.25; // 12/15px
		font-weight: $weight-medium;
		height: 2.25em; // 27px
		padding: .4167em .75em; // 5px/9px
		letter-spacing: 0.1em;
	}

	&.is-medium,
	.tags.are-medium & {
		font-size: rem-calc(14) !important;
	}

	.tags & {

		&:not(:last-child) {
			margin-right: rem-calc(13);
		}
	}
}

.tags {
	&.tags--wrapping {
		@include media('<=tablet') {
			width: 100%;
		}
	}
}
