.contact-media {
  &-row {
    .column.is-4-desktop {
      [class*='is-8'].column & {
        @media(min-width: 1024px){
          width: 50%!important;
        }
      }
      [class*='is-4'].column &, [class*='is-6'].column & {
        width: 100%!important;
        flex:none!important;
      }
    }
  }
  &.has-background-white  {
    * {
      color: $blue-darkest !important;
    }
    a {
      &:hover, &:hover * {
        color: $pink !important;
      }
    }
  }
  .contacts-list {
    margin-top: 10px;
    font-size: 15px;
    display: flex;
    flex-wrap:wrap;
    gap: 8px 15px;
    li {
      margin: 0!important;
    }
  }
  .contact-card {
    padding:0 2.05em;
  }
}
.contacts-block {
  word-break: break-word;

  &:not(:last-child) {
    margin-bottom: rem-calc(28);
  }
}

.contacts-list {
  color: $info;

  li:nth-child(even):not(:last-child) {
    margin-bottom: 15px;
  }

  .icon:hover {
    color: $pink;
  }
}

.card, .block_contact {
  .card-content {
    .content {
      address {
        ul.social-networks {
          @include media('<tablet') {
            display: flex;
          }

          li {
            a {
              i.icon {
                font-size: 1.25rem !important;

                @include media('<tablet') {
                  padding-right: rem-calc(5);
                }
              }
            }
          }
        }
      }
    }
  }
}
