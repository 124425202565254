.label,
label {
  display: block;
  font-size: rem-calc(13);
  line-height: 1.6154; // 21px
  font-weight: $weight-medium;
  color: $dark;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
}

.input,
.textarea,
.select {
  font-weight: $weight-medium;

  &:not(.is-small):not(.is-medium):not(.is-large) {
    font-size: $size-small; // 12px/20px
  }
}

.input,
.textarea,
.select select {
  [class*="has-background-"]:not(.has-background-white) &,
  &:focus,
  &.is-focused,
  &:active,
  &.is-active {
    background-color: $white;
  }

  [class*="has-background-"]:not(.has-background-white) & {
    border-color: $white;

    &:focus,
    &.is-focused,
    &:active,
    &.is-active {
      border-color: $input-focus-border-color;
    }

    &:hover {
      border-color: $input-hover-border-color;
    }
  }
}

.input {
  &:not(.is-small):not(.is-medium):not(.is-large) {
    height: 4.1667em;
    padding-top: 1.0833em; // 13px
    padding-bottom: 1.0833em; // 13px
  }

  &.is-small {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }

  &.is-medium {
    height: 3.3125em; // 53px
    padding-top: 0.6875em; // 11px
    padding-bottom: 0.6875em; // 11px
  }

  &.is-large {
    height: 2.1em; // 63px
    padding-top: 0.1333em; // 4px
    padding-bottom: 0.1333em; // 4px
  }
}

.select {
  &,
  select {
    display: block;
    width: 100%;
  }

  &:not(.is-small):not(.is-medium):not(.is-large):not(.is-multiple) {
    &,
    select {
      height: 4.1667em;
    }

    select {
      padding-top: 1.0833em; // 13px
      padding-bottom: 1.0833em; // 13px
    }
  }

  &.is-small:not(.is-multiple) {
    select {
      padding-top: 0.5em;
      padding-bottom: 0.5em;
    }
  }

  &.is-medium:not(.is-multiple) {
    height: 3.3125em; // 53px

    select {
      height: 3.3125em; // 53px
      padding-top: 0.6875em; // 11px
      padding-bottom: 0.6875em; // 11px
    }
  }

  &.is-large:not(.is-multiple) {
    height: 2.1em; // 63px

    select {
      height: 2.1em; // 63px
      padding-top: 0.1333em; // 4px
      padding-bottom: 0.1333em; // 4px
    }
  }

  &:not(.is-multiple):not(.is-loading) {
    &:after {
      // @include size(.75em);
      // border-width: 2px;
      display: none;
    }
  }
}

input[type="text"],
input[type="tel"],
input[type="email"],
input[type="search"],
input[type="password"],
input[type="url"],
input[type="date"],
textarea {
  -webkit-appearance: none;

  &:not(.input):not(.textarea) {
    @include animate(border-color background box-shadow color, 0.15s, ease-out);
    display: block;
    width: 100%;
    height: 4.1667em; // 50px
    padding: 1.0833em 1.25em; // 13px/15px
    font-size: $size-small !important; // 12px
    line-height: 1.7333; // 28px
    font-weight: $weight-medium; // 500
    color: $grey-darker;
    background-color: $input-background-color;
    border-radius: $input-radius;
    border: $control-border-width solid $input-border-color;
    box-shadow: $input-shadow;

    &::-ms-expand {
      background-color: transparent;
      border: 0;
    }

    &::placeholder {
      color: $input-placeholder-color;
      opacity: 1;
    }

    [class*="has-background-"]:not(.has-background-white) & {
      background-color: $white;
      border-color: $white;

      &:focus &:active {
        border-color: $input-focus-border-color;
      }
    }

    &:focus,
    &:active {
      background-color: $white;
      border-color: $input-focus-border-color;
      box-shadow: 0 0 0 0.125em $input-focus-box-shadow-color;
    }

    &:hover {
      border-color: $input-hover-border-color;
    }
  }
}

textarea {
  &:not(.input):not(.textarea) {
    height: initial;
    min-height: rem-calc(92);
    resize: vertical;
  }
}

.datepicker {
  .icon-calendar {
    @include size(auto);
    color: $dark;
    position: absolute;
    left: rem-calc(14);
    top: 50%;
    transform: translateY(-50%);
    font-size: rem-calc(20);
    line-height: 1;
    font-weight: $weight-normal;
    pointer-events: none;
  }

  input:not(.input):not(.textarea),
  .input {
    padding-left: rem-calc(40);
    padding-right: rem-calc(30);
    background: $input-background-color
      url("data:image/svg+xml,%3Csvg width='1024' height='1024' xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 1024 1024'%3E%3Cpath  d='M804.042 382.653c0 10.779-2.695 18.863-10.779 26.947l-256 256c-13.474 13.474-37.726 13.474-51.2 0l-256-256c-13.474-13.474-13.474-37.726 0-51.2s37.726-13.474 51.2 0l229.053 229.053 229.053-229.053c13.474-13.474 37.726-13.474 51.2 0 8.084 5.389 13.474 16.168 13.474 24.253z' fill='%2318214d'%3E%3C/path%3E%3C/svg%3E")
      no-repeat right 12px center;
    background-size: rem-calc(20) rem-calc(20);
  }
}

select {
  background: $input-background-color
    url("data:image/svg+xml,%3Csvg width='1024' height='1024' xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 1024 1024'%3E%3Cpath  d='M804.042 382.653c0 10.779-2.695 18.863-10.779 26.947l-256 256c-13.474 13.474-37.726 13.474-51.2 0l-256-256c-13.474-13.474-13.474-37.726 0-51.2s37.726-13.474 51.2 0l229.053 229.053 229.053-229.053c13.474-13.474 37.726-13.474 51.2 0 8.084 5.389 13.474 16.168 13.474 24.253z' fill='%2318214d'%3E%3C/path%3E%3C/svg%3E")
    no-repeat right 12px center;
  background-size: rem-calc(20) rem-calc(20);

  :not(.select) > & {
    @include animate(border-color background box-shadow color, 0.15s, ease-out);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: block;
    width: 100%;
    padding: 1.0833em 1.25em; // 13px/15px
    padding-right: 3.3333em; // 40px
    font-size: $size-small; // 12px
    line-height: 1.7333; // 28px
    font-weight: $weight-medium; // 500
    color: $grey-darker;
    border-radius: $input-radius;
    border: $control-border-width solid $input-border-color;
    box-shadow: $input-shadow;
    background-color: $input-background-color;

    &:not([multiple]) {
      height: 4.1667em; // 50px
      background: $input-background-color
        url("data:image/svg+xml,%3Csvg width='1024' height='1024' xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 1024 1024'%3E%3Cpath  d='M804.042 382.653c0 10.779-2.695 18.863-10.779 26.947l-256 256c-13.474 13.474-37.726 13.474-51.2 0l-256-256c-13.474-13.474-13.474-37.726 0-51.2s37.726-13.474 51.2 0l229.053 229.053 229.053-229.053c13.474-13.474 37.726-13.474 51.2 0 8.084 5.389 13.474 16.168 13.474 24.253z' fill='%2318214d'%3E%3C/path%3E%3C/svg%3E")
        no-repeat right 12px center;
      background-size: rem-calc(20) rem-calc(20);
    }

    &:focus,
    &:active {
      background-color: $white;
      border-color: $input-focus-border-color !important;
      box-shadow: 0 0 0 0.125em $input-focus-box-shadow-color;
    }

    &:hover {
      border-color: $input-hover-border-color;
    }

    &::-ms-expand {
      display: none;
    }
  }

  [class*="has-background-"]:not(.has-background-white) :not(.select) > & {
    background-color: $white;
    border-color: $white;

    &:hover {
      border-color: $input-hover-border-color;
    }
  }
}

input[type="submit"],
button[type="submit"],
button[type="reset"],
input[type="reset"] {
  &:not(.button) {
    @include animate(border-color background box-shadow color, 0.15s, ease-out);
    color: $white;
    border-radius: $radius;
    background-color: $link;
    border: $button-border-width solid $link;
    display: inline-block;
    vertical-align: middle;
    font-size: rem-calc(16);
    line-height: 1.2941; // 22px
    font-weight: $weight-bold;
    padding: $button-padding-vertical $button-padding-horizontal;
    text-align: center;
    margin: 0;
    cursor: pointer;

    &:hover {
      border-color: $link-hover;
      background-color: $link-hover;
    }

    &:focus,
    &:active {
      box-shadow: 0 0 0 0.125em rgba($link, 0.25);
    }
  }
}

.custom-control-list {
  &:last-child {
    margin-bottom: rem-calc(-8);
  }
}

.custom-control {
  display: inline-block;
  vertical-align: top;
  padding-left: rem-calc(32);
  padding-bottom: 0;
  margin-bottom: rem-calc(8);
  position: relative;

  &:hover {
    .custom-control-input:not(:checked) {
      ~ .custom-control-indicator {
        border-color: $input-hover-border-color;
      }
    }
  }

  .custom-control-indicator {
    @include animate(border-color box-shadow background, 0.15s, ease-out);
    @include size(rem-calc(18));
    border-radius: $radius-xsmall;
    border: 1px solid $input-border-color;
    background: $input-background-color;
    position: absolute;
    left: 0;
    margin-top: 2px;

    [class*="has-background-"]:not(.has-background-white) & {
      background: $white;
      border-color: $white;
    }
  }

  .custom-control-label {
    display: inline-block;
    vertical-align: top;
    font-size: rem-calc(17);
    line-height: 1.5294; // 26px
    color: $info;
    font-weight: $weight-semibold;
  }

  .custom-control-input {
    position: fixed;
    left: 0;
    top: 0;
    opacity: 0;
    z-index: -1;

    &:checked {
      ~ .custom-control-indicator {
        background: $white;
        border-color: $input-focus-border-color;

        &:before {
          opacity: 1;
        }
      }
    }

    &:focus {
      ~ .custom-control-indicator {
        border-color: $input-focus-border-color;
      }
    }
  }
}

.custom-checkbox {
  .custom-control-indicator {
    z-index: 1;
    color: $dark;

    &:before {
      @include animate(opacity, 0.15s, ease-out);
      @include size(rem-calc(10));
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: url("data:image/svg+xml,%3Csvg width='32' height='32' xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 32 32'%3E%3Cpath d='M10.975 27.222l-10.181-10.181c-0.284-0.283-0.459-0.675-0.459-1.108s0.175-0.824 0.459-1.108l2.215-2.215c0.283-0.284 0.675-0.459 1.108-0.459s0.824 0.175 1.108 0.459l6.858 6.858 14.692-14.69c0.283-0.284 0.675-0.459 1.108-0.459s0.824 0.175 1.108 0.459l2.215 2.215c0.284 0.283 0.459 0.675 0.459 1.108s-0.175 0.824-0.459 1.108l-18.015 18.013c-0.283 0.284-0.675 0.459-1.108 0.459s-0.824-0.175-1.108-0.459v0z' fill='%2318214d'%3E%3C/path%3E%3C/svg%3E")
        no-repeat;
      background-size: rem-calc(10) rem-calc(10);
      opacity: 0;
    }
  }
}

.custom-radio {
  .custom-control-indicator {
    border-radius: 50%;

    &:before {
      @include animate(opacity, 0.15s, ease-out);
      content: "";
      border-radius: 50%;
      position: absolute;
      top: rem-calc(3);
      left: rem-calc(3);
      right: rem-calc(3);
      bottom: rem-calc(3);
      background: $dark;
      opacity: 0;
    }
  }
}

input[type="date"] {
  padding-left: 1.5em !important;
}

form {
  input[type="checkbox"] + label {
    display: inline;
    vertical-align: text-bottom;
    padding-left: rem-calc(8);
  }
}

.input-select {
  display: flex;
  align-items: center;
  min-height: 50px;
  border: 1px solid #e7f5f3;
  border-radius: 5px;
  background-color: #fff;
  position: relative;

  .vd-wrapper {
    margin: 0 15px;
    input {
      outline: none !important;
      box-shadow: none !important;
      border: none !important;
      background: none !important;
      padding: 15px !important;
    }
    .vd-picker__input-clear {
      align-self: initial;
    }
  }
  .vs__tools {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%) !important;
    display: flex;
    align-items: center;
    gap: 5px;
    z-index: 1;
    .length {
      font-size: 12px;
    }
  }
  .vs_clear {
    cursor: pointer;
    width: 12px;
    color: $content-heading-color;
    margin-bottom: 0 !important;
    aspect-ratio: 1;
    svg {
      display: block;
    }
  }
  .btn-search {
    transition: color 0.15s ease-out;
    background: transparent !important;
    border: 0;
    padding: 0.3125rem;
    margin: 0;
    border-radius: 0;
    color: $content-heading-color;
    font-size: 1.125rem;
    line-height: 1;
    padding: 0;
    cursor: pointer;
    &:hover {
      color: $link;
    }
  }
}
.vs__dropdown-menu {
  font-size: 0.75rem;
  border-radius: 5px;
  border: none;
  margin-top: 5px;
  .vs__dropdown-option {
    padding: 0;
    &::before {
      display: none;
    }
    &.vs__dropdown-option--highlight {
      background-color: inherit;
      color: inherit;
    }
    &:hover {
      background-color: #5897fb;
      color: #fff;
    }
    .vs__item_option {
      display: flex;
      gap: 10px;
      padding: 0.3em 1.25em;
      pointer-events: auto;
      .vs__item_option_label {
        width: 1px;
        flex-grow: 1;
      }

      .vs__item_option_check {
        width: 12px;
        aspect-ratio: 1;
        background: #fff;
        outline: 1px solid #fff;
        border: 2px solid #5897fb;
        align-self: center;
        position: relative;
        &::before {
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          transform: scale(0.7);
          background: #5897fb;
          opacity: 0;
        }
      }
    }

    &.vs__dropdown-option--selected .vs__item_option .vs__item_option_check {
      &::before {
        opacity: 1;
      }
    }
  }
}

.vs__clear, .vd-picker__input:not(.vd-picker__input--no-date) .vd-picker__input-clear__icon .vd-icon.vd-icon {
  background: center / contain no-repeat;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzODQgNTEyIj48cGF0aCBmaWxsPSIjMTgyMTRkIiBkPSJNMzQyLjYgMTUwLjZjMTIuNS0xMi41IDEyLjUtMzIuOCAwLTQ1LjNzLTMyLjgtMTIuNS00NS4zIDBMMTkyIDIxMC43IDg2LjYgMTA1LjRjLTEyLjUtMTIuNS0zMi44LTEyLjUtNDUuMyAwcy0xMi41IDMyLjggMCA0NS4zTDE0Ni43IDI1NiA0MS40IDM2MS40Yy0xMi41IDEyLjUtMTIuNSAzMi44IDAgNDUuM3MzMi44IDEyLjUgNDUuMyAwTDE5MiAzMDEuMyAyOTcuNCA0MDYuNmMxMi41IDEyLjUgMzIuOCAxMi41IDQ1LjMgMHMxMi41LTMyLjggMC00NS4zTDIzNy4zIDI1NiAzNDIuNiAxNTAuNnoiPjwvcGF0aD48L3N2Zz4=);
  width:12px;
  height:16px;
  svg {
    display:none!important;
  }
}
.v-select {
  font-size: 0.75rem;
  flex-grow: 1;
  
  
  .vs__actions {
    min-width: 5em;
    justify-content: flex-end;
    padding: 1.0833em 1.25em 1.0833em 0;
    .vs__arrow {
      background:
        url("data:image/svg+xml,%3Csvg width='1024' height='1024' xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 1024 1024'%3E%3Cpath  d='M804.042 382.653c0 10.779-2.695 18.863-10.779 26.947l-256 256c-13.474 13.474-37.726 13.474-51.2 0l-256-256c-13.474-13.474-13.474-37.726 0-51.2s37.726-13.474 51.2 0l229.053 229.053 229.053-229.053c13.474-13.474 37.726-13.474 51.2 0 8.084 5.389 13.474 16.168 13.474 24.253z' fill='%2318214d'%3E%3C/path%3E%3C/svg%3E")
         center / cover no-repeat;
      width: rem-calc(20);
      aspect-ratio: 1;
    }
  }
  input.vs__search {
    padding: 0.05em 0 !important;
    height: auto !important;
    box-shadow: none !important;
    width: 1px !important;
    flex-basis: 1px !important;
    outline: none;
    border: none!important;
    background: transparent!important;
  }
  &.vs--multiple {
    input.vs__search {
      width: auto!important;
    }
  }
  &.vs--multiple .vs__selected {
    display: none;
    &-options {
      text-overflow: ellipsis;
      display: block!important;
      white-space: nowrap;
      overflow: hidden;
      padding: 1.0833em 0 1.0833em 1.25em;
      width: 1px !important;
      flex-basis: initial !important;
      & > span {
        padding: 0!important;
        & + span::before {
          content: ", ";
        }
        & + input {
          width: 0px!important;
          position: absolute;
        }
      }
      
    }
  }
  .vs__selected {
    z-index: 9;
  }
  .vs__selected-options > * {
    margin: 0 !important;
    flex-shrink: 0;
    padding: 0.218em 0.6em;
  }
  .vs__selected-options {
    flex-wrap: nowrap;
    overflow: hidden;
    padding: 1.0833em 0 1.0833em 1.25em;
    width: 1px !important;
    flex-basis: initial !important;
  }

  .vs__dropdown-toggle {
    padding: 0;
    border: none !important;
    background: transparent;
  }
}

.field-date-picker {
  .vd-picker__input input {
    padding-left:30px!important;
    padding-right:30px!important;
  }
  .vd-picker__input-icon, .vd-picker__input-clear {
    position: absolute;
    margin: 0!important;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
  }
  .vd-picker__input-clear {
    right: 0;
  }
}