.search-form {
	width: 100%;

	&:not(:last-child) {
		margin-bottom: rem-calc(20);
	}

	.input:not(.is-small):not(.is-medium):not(.is-large) {
		padding-right: rem-calc(45);
	}

	.btn-search:not(.button) {
		@include animate(color, .15s, ease-out);
		background: transparent !important;
		border: 0;
		padding: rem-calc(5);
		margin: 0;
		border-radius: 0;
		color: $dark;
		font-size: rem-calc(18);
		line-height: 1;
		position: absolute;
		right: rem-calc(9);
		top: 50%;
		transform: translateY(-50%);

		.aside-column & {
			font-size: rem-calc(22);
		}

		.icon {
			@include size(auto);
			display: inline-block;
		}

		&:hover {
			color: $link;
		}
	}
}

.button-list-search {
	@include media("<tablet") {
		text-align: left;
	}

	.button {
		@include media(">desktop") {
			&:last-child {
				margin-left: rem-calc(15);
			}
		}

		@include media("<=desktop") {
			margin: {
				top: rem-calc(8);
				bottom: rem-calc(8);
			};
		}
	}
}

form.search-sort-form {
	& > .columns {
		flex-wrap: nowrap;

		.column {
			min-width: 1px;
		}
	}

	&:not(.form-404) {
		.control .btn-search {
			transform: translateY(0);
		}
	}
}
